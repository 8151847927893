<template>
  <v-card elevation="0" class="px-2">
    <v-card-title class="profile--title">
      Update Profile
      <v-spacer />
      <v-btn
        class="deep-orange lighten-4 deep-orange--text text--darken-4"
        elevation="0"
        @click="redirect()"
      >
        <v-icon class="mx-2">mdi-close</v-icon>
        Cancel
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="mt-4">
      <v-row>
        <v-col md="4" sm="12">
          <v-card elevation="0">
            <div class="d-flex justify-center">
              <v-avatar size="140">
                <v-img
                  v-if="ProfilePicture !== ''"
                  :src="ProfilePicture"
                  height="140px"
                  class="rounded"
                  contain
                ></v-img>

                <v-img
                  :src="user.avatar"
                  height="140px"
                  class="rounded"
                  contain
                  v-else-if="user.avatar !== null"
                />

                <v-img
                  src="@/assets/NRF-LOGO-LOCKUP-FINAL.png"
                  height="140px"
                  class="rounded"
                  contain
                  v-else
                />
              </v-avatar>
            </div>

            <v-card-subtitle class="text-center">
              Attach a passport Size colored photo
            </v-card-subtitle>

            <v-card-actions>
              <v-file-input
                outlined
                dense
                ref="Image"
                type="file"
                v-model="formData.Image"
                @change="attachProfilePicture"
                accept="image/png, image/jpeg"
                append-icon="mdi-paperclip"
              >
                <template v-slot:label>
                  <div>Attach Photo <span class="red--text">*</span></div>
                </template>
              </v-file-input>
            </v-card-actions>
          </v-card>
          <v-divider />
        </v-col>

        <v-divider vertical />

        <v-col md="8" sm="12">
          <v-form v-model="isValid" ref="bioForm">
            <v-row dense>
              <v-col md="4" cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="formData.firstName"
                  :rules="rules.Field"
                  ref="firstName"
                >
                  <template v-slot:label>
                    <div>First Name <span class="red--text">*</span></div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col md="4">
                <v-text-field
                  outlined
                  dense
                  v-model="formData.secondName"
                  ref="SecondName"
                >
                  <template v-slot:label>
                    <div>Middle Names <span class="red--text">*</span></div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col md="4" cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="formData.thirdName"
                  :rules="rules.Field"
                  ref="thirdName"
                >
                  <template v-slot:label>
                    <div>Last Name <span class="red--text">*</span></div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4"
                ><v-select
                  dense
                  outlined
                  :items="['Single', 'Married']"
                  v-model="formData.maritalStatus"
                  ref="maritalStatus"
                  item-value="code"
                  item-text="description"
                >
                  <template v-slot:label>
                    <div>Marital Status</div>
                  </template>
                </v-select>
              </v-col>

              <v-col md="4" cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="formData.IDNo"
                  :rules="rules.Field"
                  ref="IDNo"
                >
                  <template v-slot:label>
                    <div>ID No <span class="red--text">*</span></div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col md="4" cols="12">
                <v-select
                  dense
                  outlined
                  :items="['Male', 'Female', 'Others']"
                  v-model="formData.gender"
                  :rules="rules.Field"
                  ref="gender"
                >
                  <template v-slot:label>
                    <div>Gender <span class="red--text">*</span></div>
                  </template>
                </v-select>
              </v-col>

              <v-col md="4" cols="12">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  v-model="formData.institution"
                  :rules="rules.Field"
                  ref="institution"
                >
                  <template v-slot:label>
                    <div>Institution <span class="red--text">*</span></div>
                  </template>
                </v-text-field>
              </v-col>

              <v-col md="6" cols="12">
                <v-switch
                  v-model="formData.personWithDisability"
                  ref="Disability"
                  inset
                  label="Any form of disability"
                  class="mt-0"
                />

                <v-textarea
                  v-if="formData.personWithDisability && editable"
                  v-model="formData.pWDNo"
                  :rules="rules.pWDNo"
                  ref="pWDNo"
                  outlined
                  label="Specify disability or pWDNo"
                  rows="2"
                >
                  <template v-slot:label>
                    <div>
                      Specify disability or PWD number
                      <span class="red--text">*</span>
                    </div>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-divider />
          <Contact ref="myChild" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions class="profile--actions">
      <v-spacer />
      <v-btn color="primary" @click="saveProfile" v-if="!checkBio()">
        <v-icon class="mx-2">mdi-content-save-outline</v-icon>
        Save
      </v-btn>
      <v-btn color="primary" @click="editProfile" v-if="checkBio()">
        <v-icon class="mx-2">mdi-content-save-outline</v-icon>
        Save
      </v-btn>
    </v-card-actions>

    <Spinner />
  </v-card>
</template>

<script>
import { AuthService } from "@/packages/dashboard/modules/auth";
import Spinner from "@/packages/dashboard/plugins/loader/views/Spinner.vue";
import { helper } from "@/packages/dashboard/utils";
import Contact from "@/packages/profile/modules/contact/Contact.vue";

export default {
  name: "Bio",
  components: { Spinner, Contact },
  data: function () {
    return {
      image_url: "",
      editable: true,
      edit: false,
      isValid: false,
      formData: {
        Image: undefined,
        address: "",
        city: "",
        country: "",
        county: "",
        email: "",
        firstName: "",
        secondName: "",
        thirdName: "",
        gender: "",
        IDNo: "",
        institution: "",
        maritalStatus: "",
        no: "",
        personWithDisability: false,
        phoneNo: "",
        pWDNo: "",
        disabilityDescription: "",
        b64Image: undefined,
      },
      getImageinbs64: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      Event.$on("redirect", () => {
        console.log("redirect");
        this.redirect();
      });
    });
  },
  computed: {
    rules() {
      return {
        profImage: [
          (v) => {
            return this.editable &&
              AuthService.user.profile &&
              !AuthService.user.profile.picture
              ? !!v || "Profile Picture is required"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.size < 10000000 || "Attachment should be less than 10MB"
              : true;
          },
          (v) => {
            return this.editable && !!v
              ? v.type.includes("image") || "Attachment must be an image"
              : true;
          },
        ],
        Field: [(v) => !!v || "Field is required"],
      };
    },
    user() {
      return AuthService.user;
    },
    bio() {
      return this.$store.getters["Profile/bioGetters"]("bio");
    },
    marital() {
      return this.$store.getters["Profile/bioGetters"]("maritalStatus");
    },
    ProfilePicture() {
      let profile = this.bio;

      if (this.image_url) return this.image_url;

      return profile
        ? profile.b64Image
          ? `data:image/jpeg;base64,${profile.b64Image}` ||
            `data:image/png;base64,${profile.b64Image}`
          : ""
        : "";
    },
    profImage() {
      return `data:image/jpeg;base64,${this.bio.b64Image}`;
    },
  },

  methods: {
    saveProfile: function () {
      if (!this.isValid) {
        this.$refs.bioForm.validate();
      } else {
        const data = { ...this.formData };
        data.b64Image = data.Image;
        data.no = this.user.profileID;
        this.$store.dispatch("Profile/saveBio", data);
        this.$refs.myChild.saveContact();
      }
    },
    convertTobase64: async function (url) {
      const data = await fetch(url);
      const blob = await data.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64data = reader.result;
        console.log(base64data);
        const strImage = base64data.replace(/^data:image\/[a-z]+;base64,/, "");
        this.formData.Image = strImage;
        return base64data;
      };
    },
    attachProfilePicture: function () {
      this["image_url"] = URL.createObjectURL(this.formData["Image"]);
      this.getImageinbs64 = this.convertTobase64(this.image_url);
    },
    redirect() {
      this.$router.push({
        name: "viewProfile",
      });
    },
    editProfile: function () {
      if (!this.isValid || !this.$refs.myChild.isValid) {
        this.$refs.bioForm.validate();
        this.$refs.myChild.$refs.contactForm.validate();
      } else {
        console.log("passed");
        const data = { ...this.formData };
        data.b64Image = data.Image;
        data.no = this.user.profileID;
        this.$store.dispatch("Profile/saveBio", data);
        this.$refs.myChild.updateProfileContact();
      }
    },
    checkBio() {
      if (Object.keys(this.bio).length > 0) {
        return true;
      } else {
        return false;
      }
    },
    saveFile: function (data) {
      this.$store.dispatch(`Profile/saveAttachment`, {
        profileID: this.user.profileID,
        attachData: helper.prepareFormData(data),
      });
    },
    editFile: function (data) {
      this.$store.dispatch(`Profile/editAttachment`, {
        profileID: this.user.profileID,
        attachData: helper.prepareFormData(data),
      });
    },
  },

  watch: {
    bio: {
      handler: function () {
        if (Object.keys(this.bio).length > 0) {
          this.formData = { ...this.bio };
          if (this.formData.dateOfBirth === "0001-01-01") {
            this.formData.dateOfBirth = "";
            this.formData.idNo = "";
          }
          if (this.formData.firstName === "" && this.user.firstName !== "") {
            this.formData.firstName = this.user.firstName;
            this.formData.thirdName = this.user.lastName;
          }
        }
      },
    },
  },
};
</script>

<style>
.transform {
  text-transform: uppercase;
}
.profile--title {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.profile--actions {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: #fff;
}
</style>
