import { helper } from "../../utils";
import { appName } from "@/environment/environment";
class AuthService {
  constructor() {
    this.token = helper.getLocalStorage(`${appName || "app"}_token`);
    this.user = helper.getLocalStorage(`${appName}_user`);
  }

  check() {
    return !!this.token;
  }

  setUser(user, silent = false) {
    helper.removeLocalStorage(`${appName}_user`);
    helper.storeInLocalStorage(`${appName}_user`, user);
    this.user = user;
    if (!silent) location.reload();
  }

  login(token, user) {
    this.token = token;
    this.user = user;

    helper.storeInLocalStorage(`${appName}_token`, token);
    helper.storeInLocalStorage(`${appName}_user`, user);
    location.reload();
  }
  async autoLogin(token, user) {
    window.localStorage.setItem(`${appName}_token`, token);
    window.localStorage.setItem(`${appName}_user`, JSON.stringify(user));

    this.token = token;
    this.user = user;

    return await this.token;
  }

  logout() {
    helper.removeLocalStorage(`${appName}_user`);
    helper.removeLocalStorage(`${appName}_token`);
    //location.reload();
    window.localStorage.removeItem("data");
    window.localStorage.removeItem("applicationSetup");
    window.location.href = "/";
  }
}

export default new AuthService();
