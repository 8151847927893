<template>
  <v-card class="py-2">
    <v-row>
      <v-col cols="12" md="7">
        <v-card tile flat class="py-1">
          <v-card-text>
            <h1 class="mb-2">NRF Grants Application</h1>
            <h5 class="subtitle-1 mb-2">
              Concept notes should be submitted together with this application
            </h5>
            <p>
              Only successful applicants will be invited to submit full
              proposals.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" v-if="$vuetify.breakpoint.mdAndUp">
        <v-img contain src="@/assets/NRF-LOGO-LOCKUP-FINAL.png" width="400" />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { helper } from "@/packages/dashboard/utils";

export default {
  name: "AboutNrfGrants",
  methods: {
    formatDate(date) {
      return helper.getDateFormat(date);
    },
  },
};
</script>
