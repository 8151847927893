<template>
  <v-container>
    <v-card class="mt-n2" flat v-if="!isEmpty()">
      <v-card-title class="mb-0 pb-0" v-if="isApply">
        <v-spacer />
        <v-btn
          v-if="!checkIfGrantIsApplied()"
          small
          class="mx-1"
          @click="viewGrantNote()"
        >
          View Grant Call Note
        </v-btn>
        <v-btn
          v-if="!checkIfGrantIsApplied()"
          @click="openDialog(specificGrant)"
          class="mx-1"
          color="primary"
          small
        >
          Apply
        </v-btn>
        <v-card-text class="mt-n3 mb-n5" v-else>
          <p class="">
            <span class="red--text text-subtitle-2 font-italic caption"
              ><v-icon color="red" small>mdi-alert</v-icon>You've already
              applied this grant!</span
            >
          </p>
          <v-col justify="center"> </v-col>
        </v-card-text>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters class="caption">
          <v-col cols="12" md="6"
            >Status:<span class="green--text font-weight-black caption ml-1">
              {{
                specificGrant.status
                  ? getStatus(specificGrant.grantStatus)
                  : "Open"
              }}</span
            ></v-col
          >
          <v-col cols="12" md="12"
            >Application Deadline:<span
              :class="'red--text font-weight-black caption ml-1'"
            >
              {{ formatDate(specificGrant.endDate) }}
              <!-- 20<sup>th</sup> Jun 2023 -->
            </span></v-col
          >
        </v-row>
      </v-card-text>

      <v-divider />
      <section class="scroller">
        <v-card-text>
          <h3 class="black--text font-subtitle-1 font-weight-medium">
            {{
              specificGrant
                ? `${specificGrant.title + " - " + specificGrant.no}`
                : "Failed to load title"
            }}
          </h3>

          <p class="caption text-justify">
            {{
              specificGrant.grantSections.length > 0
                ? specificGrant.grantSections[0]["description"]
                : `No description set`
            }}
          </p>

          <div class="mt-4">
            <h4 class="black--text subtitle-2">Budget and Grant duration</h4>
            <ul
              class="caption"
              v-for="(line, i) in specificGrant.grantLines"
              :key="i"
            >
              <li>
                <p>
                  The budget available for this call is
                  <span class="font-weight-bold"> {{ line.amount }} </span>.
                  Only <b>{{ line.quantity }}</b> projects will be considered
                  for a grant of up to a maximum of KES.
                  <span>{{ line.unitCost }}</span>
                  each.
                </p>
              </li>
              <!-- <li>
                The project’s expected duration is a maximum of eighteen
                <b>(18) </b>months
              </li> -->
            </ul>
            <h4 class="black--text font-subtitle-1 font-weight-medium">
              Grant Requirements
            </h4>
            <ul
              class="caption"
              v-if="
                specificGrant && specificGrant.conceptNoteOutlines.length > 0
              "
            >
              <li
                v-for="({ title }, i) in specificGrant.conceptNoteOutlines"
                :key="i"
              >
                <p>{{ title }}</p>
              </li>
            </ul>
            <p v-else>Check on the Apply section</p>
          </div>
        </v-card-text>
      </section>
    </v-card>
    <v-card flat>
      <v-skeleton-loader
        v-if="isEmpty()"
        v-bind="boilerplate"
        type="card-heading, list-item-three-line, list-item-three-line, list-item-three-line"
      ></v-skeleton-loader>
    </v-card>
  </v-container>
</template>
<script>
import { AuthService } from "../../auth";
import { helper } from "@/packages/dashboard/utils";
export default {
  name: "SelectedGrantCard",
  components: {},
  props: {
    specificGrant: { type: Object, default: () => {} },
    isApply: {
      type: Boolean,
      default: true,
    },
    grantRequirement: { type: Array, default: () => [] },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    // grants() {
    //   return this.$store.getters["Grant/grantGetters"]("grants");
    // },
    //applications
    boilerplate() {
      return { boilerplate: !this.loading };
    },
    applications() {
      return this.$store.getters["Application/applicationGetters"](
        "applications"
      );
    },
    user() {
      return AuthService.user;
    },
  },
  methods: {
    applyGrantHandler() {
      this.$router.push({
        name: "Check",
        params: { refNo: this.grant.no },
      });
      //window.localStorage.setItem("grantRefNo", this.grant.no);
    },
    checkUndefined() {
      if (this.specificGrant == undefined) {
        return this.grants[0];
      }
    },
    isEmpty() {
      return this.specificGrant === undefined;
    },
    getStatus(val) {
      if (val === "Open") {
        return "Open for Application";
      } else {
        return val;
      }
    },
    formatDate(date) {
      return helper.getDateFormat(date);
    },
    //func ()-> checkIfApllied
    checkIfGrantIsApplied: function () {
      return this.applications.some(
        (a) =>
          a.grant === this.specificGrant.no &&
          a.applicant === this.user.profile.no
      );
    },
    viewConceptNote() {
      this.$router.push({
        name: "GrantCard",
        params: {
          no: this.specificGrant.no,
          cnote: { conceptNote: this.specificGrant.conceptNote },
        },
      });
    },
    viewGrantNote() {
      this.$root.routeTo({
        name: "GrantCard",
        params: {
          no: this.specificGrant.no,
        },
      });
    },
    openDialog(grant) {
      this.$confirm.show({
        title: "Create Application",
        text: `Are you sure you want to apply for grant ${grant.no}?`,
        onConfirm: () => {
          if (AuthService.check()) {
            this.$store.dispatch("Application/saveApplication", {
              grant: grant.no,
              title: grant.title,
              applicant: this.user.profile.no,
            });
          } else {
            this.$router.push({
              name: "login",
              query: { no: btoa(grant.no) },
            });
          }
        },
      });
    },
  },
};
</script>
<style scoped>
.scroller {
  display: flex !important;
  flex-direction: column;
  height: 70vh;
}
.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
