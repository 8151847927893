<template>
  <v-card class="mx-auto" flat>
    <div id="topHeader"></div>
    <div class="elevation-5">
      <v-card-title class="text-h6">
        <span class="mr-0">{{
          isEdit
            ? `Update Grant Application - ${
                $root.decryptRoute($route).query.no
              } `
            : ""
        }}</span>
        <span class="text-uppercase text-body-1 font-weight-bold ml-2"
          >{{ grant.title ?? "" }} {{ `(${grant.grant ?? ""})` }}</span
        >
        <v-spacer />
        <v-btn
          class="text text-capitalize red--text"
          text
          @click="$router.back()"
        >
          <v-icon>arrow_back</v-icon>
          Cancel
        </v-btn>
      </v-card-title>

      <v-card-subtitle
        >Fill in the below sections to complete your application
      </v-card-subtitle>

      <v-divider />
    </div>
    <ApplicationFormVue :grant="grant" />
  </v-card>
</template>
<script>
import ApplicationFormVue from "./ApplicationForm.vue";
import { AuthService } from "@/packages/dashboard/modules/auth";
export default {
  name: "GrantApplication",
  mixins: [],
  components: { ApplicationFormVue },
  data() {
    return {
      tab: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (!AuthService.check()) {
        v.$router.push({
          name: "login",
          query: { no: to.params.no },
        });
      }
      v.$store.dispatch("Grant/getGrant", v.$root.decryptRoute(to).params.no);
      v.$store.dispatch(
        "Setup/getApplicationSetup",
        v.$root.decryptRoute(to).params.no
      );

      if (to.query.no) {
        v.$store.dispatch("Application/getApplication", {
          no: v.$root.decryptRoute(to).query.no,
        });

        v.$store.dispatch("Application/getCoInvestigators", {
          no: v.$root.decryptRoute(to).query.no,
        });

        v.$store.dispatch("Application/getProjectTeam", {
          no: v.$root.decryptRoute(to).query.no,
        });
      }
      //get checklist
      v.$store.dispatch("Setup/getChecklists");
    });
  },
  mounted() {
    this.$vuetify.goTo("#topHeader", {
      duration: 500,
      offset: 10,
      easing: "easeInOutCubic",
    });
  },
  computed: {
    grant: function () {
      return this.$store.getters["Application/applicationGetters"](
        "application"
      );
    },
    isEdit() {
      return this.$route.query.no;
    },
  },
  actions: {},
  methods: {},
  watch: {},
};
</script>
