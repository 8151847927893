<template>
  <v-app id="inspire">
    <AppHeader />
    <v-main class="mb-8 pb-8 mt-n16">
      <router-view name="action"></router-view>
      <router-view name="view"></router-view>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        fixed
        bottom
        right
        style="margin-bottom: 3%"
        class="primary"
        @click="toTop"
      >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-main>
    <SpinnerVue />
    <ConfirmationVue />
    <AppFooter />
  </v-app>
</template>
<script>
import AppHeader from "./AppHeader.vue";
import AppFooter from "./AppFooter.vue";
import SpinnerVue from "@/packages/dashboard/plugins/loader/views/Spinner.vue";
import ConfirmationVue from "@/packages/dashboard/plugins/confirmation/Confirmation.vue";
export default {
  name: "AppLayout",
  components: { AppHeader, AppFooter, SpinnerVue, ConfirmationVue },
  data: function () {
    return {
      fab: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Auth/company");
    });
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
