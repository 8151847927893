<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600">
      <v-card height="350">
        <v-card-title class="text-h5 text-center primary lighten-2">
          <v-spacer />
          <h1
            class="text-center mb-3 font-weight-light"
            style="font-size: 40px"
          >
            <p>Verify OTP code</p>
          </h1>
          <v-spacer />
        </v-card-title>

        <v-card-text>
          <v-otp-input
            length="6"
            v-model="otp_code"
            @finish="verifyOtp"
            class="mt-6"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn :loading="$store.getters['loading']" @click="sendOtp">
            Resend OTP
          </v-btn>

          <v-spacer />

          <v-btn
            :disabled="otp_code.length < 6"
            :loading="$store.getters['loading']"
            @click="verifyOtp"
            color="primary"
          >
            Verify OTP
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Verify",
  props: {
    userEmail: String,
  },
  data: function () {
    return {
      otp_code: "",
    };
  },
  mounted() {
    Event.$on("redirectGrantApplication", () => {
    //   this.$root.routeTo({
    //     name: "GrantLists",
    //     // params: { no: this.$root.decryptRoute(this.$route).query.no },
    //   });
        this.$router.push({
        name: "GrantLists",
        
      });
    });
  },
  computed: {
    dialog: {
      get() {
        return this.$store.getters["Auth/otpModal"];
      },
      set(value) {
        this.$store.dispatch("openOtp", value);
      },
    },
  },

  methods: {
    verifyOtp: function () {
      const data = {
        otp: this.otp_code,
        email: this.userEmail,
      };
      this.$store.dispatch("Auth/verifyOtp", data);
    },

    sendOtp: function () {
      const data = {
        email: this.userEmail,
      };
      this.$store.dispatch("Auth/sendOtp", data);
    },
  },
};
</script>

<style scoped></style>
