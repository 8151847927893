<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-btn text small color="deep-orange" @click="$router.back()">
          <v-icon left> mdi-arrow-left </v-icon>
          Go Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="primary"
          class="mx-2"
          @click="
            downloadBase64File(document, `ProposalForm_${$route.params.no}`)
          "
        >
          <v-icon left color="white"> mdi-download </v-icon>
          Download
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <pdf :src="document" v-if="document" />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import pdf from "@/packages/dashboard/plugins/pdf/PdfReader.vue";
import FileMixin from "@/packages/dashboard/mixin/FileMixin.js";
export default {
  name: "ProposalForm",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Application/getDocument", v.$route.params);
    });
  },
  mixins: [FileMixin],
  components: { pdf },
  computed: {
    document() {
      return this.$store.getters["Application/applicationGetters"](
        "proposalForm"
      );
    },
  },
};
</script>
