//import DashboardLayout from './views/DashboardLayout.vue';
//import Profile from '@/packages/profile';
import AppLayout from "../../../../components/AppLayout";

export default [
  {
    path: "/dashboard",
    component: AppLayout,
    children: [
      {
        path: "",
        redirect: "/applicant",
      },
    ],
  },
];
