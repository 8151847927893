export default {
  methods: {
    previous(currentItem) {
      const currentIndex = this.applicationSetup.indexOf(currentItem);
      this.expanded = currentIndex - 1;
      this.scrollTo(
        this.$refs[this.applicationSetup[currentIndex - 1].code][0].$el
      );
    },

    next(currentItem) {
      // Check if the user has uploaded mandatory attachments
      if (
        currentItem.attachmentMandatory &&
        !this.hasUploadedAttachment(currentItem)
      ) {
        console.log("Please upload mandatory attachments before proceeding.");
        Event.$emit(
          "ApiError",
          "Please upload mandatory attachments before proceeding."
        );
        currentItem.isValid = false;
        this.$refs[currentItem.code][0].validate();

        return;
      }
      if (
        currentItem.conceptOutlineInstruction.find((x) => x.contents) ||
        currentItem.conceptOutlineInstruction.length !== 0
      ) {
        const currentIndex = this.applicationSetup.indexOf(currentItem);
        this.expanded = currentIndex + 1;
        currentItem.isValid = true;
        currentItem.completed = true;

        this.scrollTo(this.$refs[currentItem.code][0].$el);
      } else {
        currentItem.isValid = false;

        this.$refs[currentItem.code][0].validate();
      }
    },
    scrollTo(to) {
      this.$vuetify.goTo(to, {
        duration: 500,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },
    formatData(data) {
      return {
        code: data.code,
        title: data.title,
        applicationSectionLines: data.conceptOutlineInstruction.map((x) => ({
          ...x,
          section: data.code,
          code: x.code,
          instruction: x.instruction,
          contents: x.contents,
          amount: parseFloat(x.amount),
        })),
      };
    },
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    //check for uploaded attachment
    hasUploadedAttachment(currentItem) {
      const matchingAttachment = this.attachments.find(
        (attachment) => attachment.section === currentItem.code
      );

      return !!matchingAttachment;
    },
  },
};
