import call from "@/service/http";
import profileConstants from "./profileConstants";
import { bioStore } from "./modules/bio";
import { contactStore } from "./modules/contact";

export default {
  namespaced: true,
  modules: {
    Bio: bioStore,
    Contact: contactStore,
  },
  state: {
    profile: {},
    application: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    profileGetters: (state) => (val) => state[val],
  },
  actions: {
    getProfile: ({ commit }, params) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", profileConstants.profile, params)
        .then((res) => {
          commit("MUTATE", { state: "profile", value: res.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    // updateProfile: (context, payload) => {

    // }
  },
};
