import profileStore from "./profileStore";
import profileRoutes from "./profileRoutes";
import { bioStore } from "./modules/bio";
import { contactStore } from "./modules/contact";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoutes(profileRoutes);
    }

    if (options.store) {
      options.store.registerModule("Bio", bioStore);
      options.store.registerModule("Profile", profileStore);
      options.store.registerModule("Contact", contactStore);
    }
  },
};
