export default {
  applications: (params) => `grant-applications?${getParams(params)}`,
  singleApplication: (params) => `grant-applications?${getParams(params)}`,
  saveApplication: "grant-applications",
  updateApplication: "grant-applications/update",
  reopenApplication: "grant-applications/reopen",
  submitApplication: "grant-applications/submit",
  deleteApplication: "grant-applications/delete",
  deleteAttachment: "grant-applications/delete-attachment",
  propsalForm: "grant-applications/proposal-form",
  saveCoInvestigators: `grant-applications/co-investigators`,
  getCoInvestigator: (params) =>
    `grant-applications/co-investigators?${getParams(params)}`,
  deleteCoInvestigator: "grant-applications/co-investigators/delete",
  /**project team */
  saveProjectTeamProfile: `grant-applications/project-team`,
  getProjectTeam: (params) =>
    `grant-applications/project-team?${getParams(params)}`,
  deleteProjectTeamMember: "grant-applications/project-team/delete",
};

function getParams(object) {
  return Object.keys(object)
    .map((key) => key + "=" + object[key])
    .join("&")
    .toString();
}
