<template>
  <div>
    <v-card class="mx-auto" flat v-if="isPdfLoaded">
      <!-- <v-card-title v-show="displayTitle">
        {{ title }}
        <v-spacer />
        <v-btn class="primary text-lowercase mr-2" @click="download">
          <v-icon small>download</v-icon>
          Download
        </v-btn>
      </v-card-title> -->
      <!-- <v-divider v-show="displayTitle" /> -->
      <v-card-text>
        <canvas id="pdf-canvas" width="100%" height="100%"></canvas>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <!-- next and previous buttons -->
        <v-btn
          small
          class="primary text-lowercase mr-2"
          id="prev"
          @click="prev"
        >
          <v-icon small>arrow_back_ios</v-icon>
          Previous
        </v-btn>
        <v-btn
          small
          class="primary text-lowercase mr-2"
          id="next"
          @click="next"
        >
          Next
          <v-icon small>arrow_forward_ios</v-icon>
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <!-- skeleton loader -->
    <v-card class="mx-auto" flat v-else>
      <v-card-text>
        <v-skeleton-loader
          type="card"
          class="mx-auto"
          width="100%"
          height="100%"
        ></v-skeleton-loader>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { FileMixin } from "../../mixin";
export default {
  name: "PdfReader",
  mixins: [FileMixin],
  props: {
    src: {
      type: String,
      required: true,
    },
    srcType: {
      type: String,
      default: "base64",
    },
    title: {
      type: String,
      default: "PDF",
    },
    displayTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isPdfLoaded: false,
      pageNumber: 1,
      //pageCount: 0,
      pageRendering: false,
      pageNumPending: null,
      pageNum: 1,
      scale: 1.5,
      pdfDoc: null,
    };
  },
  mounted() {
    var pdfjsLib = window["pdfjs-dist/build/pdf"];
    console.log(pdfjsLib);
    // The workerSrc property shall be specified.
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js";

    // Asynchronous download of PDF
    this.asyncPDF(pdfjsLib);
  },
  methods: {
    // download() {
    //   this.downloadFile(this.src, this.title, this.srcType);
    // },
    prev() {
      console.log("prev", this.pageNumber);
      if (this.pageNumber <= 1) {
        return;
      }
      this.pageNumber--;
      this.queueRenderPage(this.pageNumber);
    },
    next() {
      console.log("next", this.pageNumber);
      if (this.pageNumber >= this.pageCount) {
        return;
      }
      this.pageNumber++;
      this.queueRenderPage(this.pageNumber);
    },
    queueRenderPage(num) {
      if (this.renderingPage) {
        this.pageNumPending = num;
      } else {
        this.renderPage(num);
      }
    },
    async renderPage(num) {
      this.pageRendering = true;
      this.isPdfLoaded = true;

      const page = await this.pdfDoc.getPage(num);
      // Using promise to fetch the page
      const viewport = page.getViewport({ scale: this.scale });
      const canvas = document.getElementById("pdf-canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      const renderTask = page.render(renderContext);

      // Wait for rendering to finish
      await renderTask.promise;
      this.pageRendering = false;

      if (this.pageNumPending !== null) {
        // New page rendering is pending
        this.renderPage(this.pageNumPending);
        this.pageNumPending = null;
      }

      this.pageRendering = false;
      this.pageNumber = num;
      this.pageCount = this.pdfDoc.numPages;
    },
    async asyncPDF(pdfjsLib) {
      var pdflib = await pdfjsLib.getDocument({
        data: this.srcType === "base64" ? atob(this.src) : this.src,
      });

      this.pdfDoc = await pdflib.promise;

      // Initial/first page rendering
      this.renderPage(this.pageNumber);
    },
  },
};
</script>
