<template>
  <v-app-bar class="secondary accent-3 white--text" app dark>
    <Logo />
    <span
      @click="$router.push('/')"
      class="ml-2 text-overline"
      style="cursor: pointer"
    >
      {{ company.displayName }}
    </span>
    <v-spacer />

    <span class="overline mr-4 h4 white--text" v-if="check">
      Hello, {{ user.firstName }}
    </span>

    <v-chip class="mx-2" label small dark v-if="check">
      <!-- <v-icon left dark>mdi-account</v-icon> -->
      ProfileID: {{ user.profileID }}
    </v-chip>

    <v-icon
      v-if="check"
      id="menu-activator"
      x-large
      color="default"
      style="cursor: pointer"
    >
      mdi-account-circle
    </v-icon>
    <!-- <v-icon class="mb-n5 ml-n1" size="12">mdi-chevron-down</v-icon> -->

    <v-menu activator="#menu-activator" v-if="check" :offset-y="true">
      <v-list dense>
        <v-list-item
          v-if="check"
          @click="
            $root.routeTo({
              name: 'viewProfile',
              params: { no: user.profileID },
            })
          "
        >
          <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          <v-list-item-title> Profile </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="check" @click="toApplications()">
          <v-list-item-icon
            ><v-icon>mdi-file-multiple</v-icon></v-list-item-icon
          >
          <v-list-item-title>My Grants Applications</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="check" @click="logout">
          <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn
      dark
      text
      v-else
      @click="
        $root.routeTo({
          name: 'login',
        })
      "
      >Login</v-btn
    >
  </v-app-bar>
</template>
<script>
import { AuthService } from "@/packages/dashboard/modules/auth";
import Logo from "@/components/Logo.vue";
export default {
  name: "AppHeader",
  components: { Logo },
  computed: {
    user() {
      return AuthService.user;
    },
    check() {
      return AuthService.check();
    },
    company: function () {
      return this.$store.getters["Auth/company"];
    },
    onRoute() {
      return this.$route.name === "applicationList";
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("Auth/logout", {
        id: AuthService.user.id,
      });
    },
    goToDashboard: function () {
      this.$router.push({ name: "dashboard" });
    },
    toApplications() {
      this.$router.push({ name: "applicationList" });
    },
  },
};
</script>
