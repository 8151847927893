<template>
  <v-card class="mx-auto mb-3" flat>
    <v-container>
      <v-row>
        <v-col md="8">
          <v-card class="pa-2" outlined tile>
            <v-file-input
              ref="file"
              dense
              counter
              small-chips
              truncate-length="10"
              outlined
              rounded
              class="ma-2"
              label="Attach a document if any"
              :rules="mandatoryAttachmentRule"
              @change="uploadFile"
              style="max-width: 50%"
            >
              <!-- <template v-if="attachment" #prepend-inner>
                {{ attachment.fileName }}
              </template> -->
            </v-file-input>

            <template v-if="attachment">
              <v-list>
                <v-subheader> uploaded files </v-subheader>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-file</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ attachment.fileName }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      x-small
                      outlined
                      fab
                      color="red"
                      @click="deleteAttachment(attachment)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider inset></v-divider>
              </v-list>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { FileMixin } from "@/packages/dashboard/mixin";
import { helper } from "@/packages/dashboard/utils";

export default {
  name: "SpecificSectionUploads",
  mixins: [FileMixin],
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    lineNo: {
      type: String,
      default: "",
    },
    section: {
      type: String,
      default: "",
    },
    attachmentMandatory: { type: Boolean, default: false },
  },
  data: function () {
    return {};
  },
  computed: {
    attachment() {
      return this.attachments.find((item) => item.section === this.section);
    },
    mandatoryAttachmentRule() {
      if (this.attachmentMandatory) {
        return [
          (v) => !!v || "Attachment is required",

          (v) => (v && v.length > 0) || "Attachment is required",
        ];
      }
      return [];
    },
  },
  methods: {
    //upload section files
    async uploadFile(e) {
      //console.log("event file", e);
      const file = e;
      if (file) {
        let base64File = await helper.toBase64(file);
        base64File = base64File.split(",")[1];

        this.$emit("addAttachment", {
          attachment: base64File,
          fileName: file.name,
          section: this.section,
        });
      }
    },

    deleteAttachment(attachment) {
      if (attachment.tableID) {
        this.$confirm.show({
          title: "Delete Attachment",
          text: "Are you sure you want to delete this attachment? This action cannot be undone.",
          onConfirm: () => {
            this.$store.dispatch("Application/deleteAttachment", {
              id: attachment.id,
              documentType: attachment.documentType,
              lineNo: attachment.lineNo,
              no: attachment.no,
              tableID: attachment.tableID,
            });
          },
        });
      }
    },
  },
};
</script>
