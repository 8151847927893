import { AuthRoutes } from "./modules/auth";
import { DashboardStore, DashboardRoutes } from "./modules/dashboard";
import { GrantRoutes } from "./modules/grants";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoutes(AuthRoutes);
      options.router.addRoutes(DashboardRoutes);
      options.router.addRoutes(GrantRoutes);
    }

    if (options.store) {
      // options.store.registerModule("Auth", AuthStore);
      options.store.registerModule("Dashboard", DashboardStore);
      // options.store.registerModule("Grants", GrantStore);
    }
  },
};
