<template>
  <v-container>
    <v-card flat>
      <v-toolbar flat dense>
        <v-tabs v-model="tabs" align-with-title>
          <v-tab
            v-for="(tab, i) in allApplicationsStats"
            :key="i"
            :class="`${getStatusColor(tab.status)}--text ml-n2`"
          >
            <v-badge
              :content="tab.count"
              :value="tab.count"
              color="primary"
              class="mt-2"
            >
              {{ tab.status }}
            </v-badge>
          </v-tab>
        </v-tabs>

        <v-btn text small @click="$router.back()">
          <v-icon left>mdi-arrow-left</v-icon>
          back
        </v-btn>
      </v-toolbar>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col
            :cols="$vuetify.breakpoint.smAndDown ? '12' : '5'"
            v-if="applications.length !== 0"
          >
            <v-tabs-items v-model="tabs">
              <v-tab-item v-for="(tab, i) in allApplicationsStats" :key="i">
                <v-list>
                  <v-list-item dense class="mt-n5">
                    <div class="text-body-1 font-weight-bold">
                      {{ tab.status }} Applications
                    </div>
                  </v-list-item>
                  <v-list-item-group v-model="selectedJob" color="primary">
                    <template>
                      <v-list-item
                        three-line
                        v-for="(application, i) in tab.list"
                        :key="i"
                      >
                        <template v-slot:default>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ application.grant }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ application.title }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="caption">
                              <!-- 2 Days Ago -->
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text class="mb-5 primary--text">
                              <v-chip
                                :class="`${getStatusColor(
                                  application.status
                                )}--text`"
                              >
                                {{ application.status }}
                              </v-chip>
                              <!-- edit -->
                              <v-btn
                                class="mx-2 text-body-2 text-capitalize"
                                color="primary"
                                outlined
                                v-if="isEdit(application)"
                                @click="editApplication(application)"
                              >
                                Edit
                                <v-icon right>mdi-pencil</v-icon>
                              </v-btn>
                              <!-- reopen -->
                              <v-btn
                                class="mx-2 text-body-2 text-capitalize"
                                color="warning"
                                outlined
                                v-if="isReopen(application)"
                                @click="reopenApplication(application)"
                              >
                                reopen
                                <v-icon right>mdi-sync</v-icon>
                              </v-btn>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    class="ml-1"
                                    color="error"
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if="application.status === 'New'"
                                    @click="deleteApp(application)"
                                    >mdi-delete</v-icon
                                  >
                                </template>
                                <span>Delete Application</span>
                              </v-tooltip>
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col
            :cols="$vuetify.breakpoint.smAndDown ? '12' : '4'"
            v-if="applications.length === 0"
          >
            <v-skeleton-loader
              type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-divider vertical />
          <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '7'">
            <selectedGrantCard
              :specificGrant="selectedApplication"
              :isApply="false"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import selectedGrantCard from "./selectedGrantCard.vue";
import helper from "@/packages/dashboard/utils/helpers/helper.js";
import { AuthService } from "@/packages/dashboard/modules/auth";
import ApplicationMixin from "../ApplicationMixin";
export default {
  name: "ApplicationList",
  mixins: [ApplicationMixin],
  data() {
    return {
      tabs: 0,
      selectedJob: 0,
      currentDate: new Date().toISOString().slice(0, 10),
    };
  },
  components: { selectedGrantCard },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Application/getApplications", {
        applicant: AuthService.user.profile.no,
      });
    });
  },
  computed: {
    applications() {
      return this.$store.getters["Application/applicationGetters"](
        "applications"
      );
    },
    allApplicationsStats() {
      return this.applications
        ? [
            {
              status: "All",
              count: this.applications.length,
              list: this.applications,
            },
            {
              status: "New",
              count: this.applications.filter(
                (application) => application.status === "New"
              ).length,
              list: this.applications.filter(
                (application) => application.status === "New"
              ),
            },
            {
              status: "Submitted",
              count: this.applications.filter(
                (application) => application.status === "Submitted"
              ).length,
              list: this.applications.filter(
                (application) => application.status === "Submitted"
              ),
            },
            {
              status: "Review",
              count: this.applications.filter(
                (application) => application.status === "Review"
              ).length,
              list: this.applications.filter(
                (application) => application.status === "Review"
              ),
            },
            {
              status: "On Hold",
              count: this.applications.filter(
                (application) => application.status === "Onhold"
              ).length,
              list: this.applications.filter(
                (application) => application.status === "Onhold"
              ),
            },
            {
              status: "Unsuccessful",
              count: this.applications.filter(
                (application) => application.status === "Rejected"
              ).length,
              list: this.applications.filter(
                (application) => application.status === "Rejected"
              ),
            },
            {
              status: "Approved",
              count: this.applications.filter(
                (application) => application.status === "Approved"
              ).length,
              list: this.applications.filter(
                (application) => application.status === "Approved"
              ),
            },
          ]
        : [];
    },
    selectedApplication() {
      console.log(this.applications[this.selectedJob]);
      return this.applications
        ? this.applications[this.selectedJob]
          ? this.applications[this.selectedJob]
          : {}
        : {};
    },
  },
  methods: {
    getStatusColor(status) {
      return helper.getColor(status);
    },
    editApplication(app) {
      this.$root.routeTo({
        name: "GrantApplication",
        params: { no: app.grant },
        query: { no: app.no },
      });
    },
    reopenApplication(app) {
      this.$store.dispatch("Application/reopenApplication", app);
    },
    //deleteApp(item) {
    //  this.$store.dispatch("Application/deleteApplication", item);
    //},
    deleteApp(item) {
      this.$confirm.show({
        title: "Delete Application",
        text: "Are you sure you want to delete this application?",
        onConfirm: () => {
          this.$store.dispatch("Application/deleteApplication", item);
        },
      });
    },
    isReopen(app) {
      return (
        app.grants.endDate > this.currentDate && app.status === "Submitted"
      );
    },
    isEdit(app) {
      return app.status === "New" && app.grants.endDate > this.currentDate;
    },
  },
};
</script>
<style scoped>
@import url("../styles.css");
</style>
