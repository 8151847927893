import AppLayout from "@/components/AppLayout.vue";
import Profile from "./views/Profile.vue";
import UpdateProfile from "./components/UpdateProfile";
import viewProfile from "./components/viewProfile";

export default [
  {
    path: "/applicant",
    component: AppLayout,
    children: [
      {
        path: "",
        components: {
          view: Profile,
        },
        children: [
          {
            path: "",
            redirect: "/applicant/profile",
          },
          {
            path: "profile/:no",
            name: "viewProfile",
            components: {
              view: viewProfile,
            },
          },
          {
            path: "profile/:no/update",
            name: "UpdateProfile",
            components: {
              view: UpdateProfile,
            },
          },
        ],
      },
    ],
  },
];
