//import GrantLayout from './views/GrantLayout.vue';
import GrantLists from "./components/GrantLists";
import AppLayout from "../../../../components/AppLayout";
import GrantDetails from "./components/GrantDetails";

export default [
  {
    path: "/grants",
    component: AppLayout,
    children: [
      {
        path: "",
        redirect: "/grants/list",
      },
      {
        path: "list",
        name: "GrantLists",
        components: {
          view: GrantLists,
        },
      },
      {
        path: ":no",
        name: "GrantCard",
        components: {
          view: GrantDetails,
        },
      },
    ],
  },
];
