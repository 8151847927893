export default {
  namespaced: true,
  state: {
    loading: false,
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },
  },
  getters: {
    dashboardGetters: (state) => (val) => state[val],
    loading: (state) => state.loading,
  },
  actions: {
    //
  },
};
