<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-top-transition"
    width="75%"
    persistent
  >
    <v-card>
      <v-toolbar flat dark color="green">
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title class="text-h5 lighten-2">{{
        application
          ? `${application.title}-${application.grant} (${application.no})`
          : "N/A"
      }}</v-card-title>
      <section class="scroller">
        <v-card-text v-if="document">
          <h4 class="black--text font-subtitle-1 font-weight-medium">
            Grant Proposal
          </h4>

          <v-card outlined max-height="150" max-width="300" class="my-2">
            <v-list-item>
              <div class="d-flex" style="cursor: pointer" @click="viewFile()">
                <v-list-item-icon>
                  <v-chip label color="blue lighten-4" small>
                    <v-icon small color="blue">mdi-file-outline</v-icon>
                  </v-chip>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title> Grant Proposal.pdf </v-list-item-title>
                </v-list-item-content>
              </div>

              <v-list-item-action>
                <v-icon
                  size="20"
                  @click="
                    downloadBase64File(
                      document,
                      `ProposalForm_${application.no}`
                    )
                  "
                  >mdi-tray-arrow-down</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </v-card-text>

        <v-divider />
        <v-card-text
          v-if="application && application.applicationSections"
          class="mt-n4"
        >
          <h4 class="black--text font-subtitle-1 font-weight-medium">
            Grant Sections
          </h4>

          <v-list dense>
            <template v-for="(section, i) in application.applicationSections">
              <v-subheader :key="'subheader-' + i">
                {{ section.code }}{{ section.title }}
                <v-spacer></v-spacer>
                {{ section.totalAmount }}
              </v-subheader>

              <v-divider :key="'divider-' + i"></v-divider>

              <v-list-item :key="'list-item-' + i">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ section.contents }}
                  </v-list-item-title>

                  <v-list-item-subtitle v-if="section.hasLines">
                    <ul
                      class="caption"
                      v-for="line in section.applicationSectionLines"
                      :key="'line-' + line.code"
                    >
                      <li>
                        {{ line.title }}
                        <p>
                          <span class="font-weight-bold text-wrap">{{
                            line.instruction
                          }}</span>
                          <br />
                          {{ line.contents }}
                          {{ line.amount > 0 ? "Amount: " + line.amount : "" }}
                        </p>
                      </li>
                    </ul>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </section>

      <v-card flat>
        <v-skeleton-loader
          v-if="isEmpty()"
          type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
        />
      </v-card>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="closeDialog"> Cancel</v-btn>
        <v-btn color="primary" text @click="submitApplication(application)">
          Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import FileMixin from "@/packages/dashboard/mixin/FileMixin.js";
import ApplicationMixin from "../ApplicationMixin";
import { AuthService } from "@/packages/dashboard/modules/auth";
export default {
  name: "FilledConceptNote",
  // props: {
  //   specificGrantApplication: { type: Object, default: () => {} },
  //   isApply: { type: Boolean, default: true },
  // },
  mixins: [FileMixin, ApplicationMixin],
  data() {
    return {
      dialog: true,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Application/getApplication", {
        applicant: AuthService.user.profile.no,
        no: v.$root.decryptRoute(to).params.no,
      });
    });
  },

  computed: {
    document() {
      return this.$store.getters["Application/applicationGetters"](
        "proposalForm"
      );
    },
    application() {
      return this.$store.getters["Application/applicationGetters"](
        "application"
      );
    },
  },
  methods: {
    isEmpty() {
      return (
        this.application === undefined ||
        Object.keys(this.application).length === 0
      );
    },
    submitApplication(app) {
      this.$confirm.show({
        title: `Submit ${this.application.grant} application`,
        text: "Are you sure? Once you submit the grant application you won't be able to edit it",
        onConfirm: () => {
          this.$store.dispatch("Application/submitApplication", app);
        },
      });
    },

    viewFile() {
      this.$router.push({
        name: "proposalForm",
        params: { no: this.application.no },
      });
    },
    closeDialog() {
      this.dialog = false;
      Event.$emit("back");
    },
  },
  watch: {
    application() {
      if (!this.isEmpty())
        this.$store.dispatch("Application/getDocument", {
          no: this.application.no,
        });
      console.log(this.application);
    },
  },
};
</script>
<style scoped>
@import url("../styles.css");
</style>
