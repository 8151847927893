/* eslint-disable no-prototype-builtins */
import call from "../../../../service/http";
import bioConstants from "./bioConstants";

export default {
  state: {
    bio: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    bioGetters: (state) => (val) => state[val],
  },
  actions: {
    getBio: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", bioConstants.bio)
        .then((res) => {
          commit("MUTATE", { state: "bio", value: res.data.data[0] });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    saveBio: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("patch", bioConstants.bio, data)
        .then(() => {
          Event.$emit("redirect");
          // dispatch("getBio");
          // dispatch("Profile/getProfile", null, { root: true });
          Event.$emit("ApiSuccess", "Successfully Saved Bio Data");
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiError",
            err.hasOwnProperty("response") ? err.response.data.message : err
          );
        });
    },

    updateBio: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("patch", bioConstants.bio, data)
        .then(() => {
          Event.$emit("redirect");
          // dispatch("getBio");
          // dispatch("Profile/getProfile", null, { root: true });
          // dispatch("Auth/user", null, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
  },
};
