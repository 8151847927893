<template>
  <v-container class="">
    <v-card flat v-if="!isEmpty()" class="mx-auto">
      <!-- <v-card-title class="justify-center"> Grant Call Note </v-card-title> -->
      <v-divider></v-divider>
      <v-card-title class="justify-center">
        <v-btn
          class="lighten-4 deep-orange--text text--darken-4"
          elevation="0"
          @click="redirect()"
        >
          <v-icon class="mx-2">mdi-arrow-left</v-icon>
          Go back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="deep-green lighten-4 orange--text text--darken-4 mx-2"
          elevation="0"
          @click="
            downloadBase64File(
              grantNoteDoc,
              `Call Concept Note-${singleGrant.no}`
            )
          "
        >
          <v-icon class="mx-2">mdi-download</v-icon>
          download
        </v-btn>
        <v-btn
          class="light-green-lighten-1 text--darken-4 mx-2"
          elevation="0"
          color="success"
          :disabled="checkIfGrantIsApplied()"
          @click="openDialog(singleGrant)"
          >Apply
          <v-icon>mdi-check-all</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="justify-center">
        <pdf :src="grantNoteDoc" v-if="grantNoteDoc" class="elevation-2" />
        <v-container v-else class="d-flex">
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
          ></v-skeleton-loader>
        </v-container>
      </v-card-title>
    </v-card>
  </v-container>
</template>
<script>
import { AuthService } from "../../auth";
import pdf from "@/packages/dashboard/plugins/pdf/PdfReader.vue";
import FileMixin from "@/packages/dashboard/mixin/FileMixin.js";
import RouteMixin from "@/packages/dashboard/mixin/RouteMixin";

export default {
  name: "GrantDetails",
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: true,
        width: "100%",
        elevation: 2,
      },
    };
  },
  mixins: [FileMixin, RouteMixin],
  components: { pdf },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Grant/getSingleGrant", {
        no: v.$root.decryptRoute(to).params.no,
      });
      //v.$store.dispatch("Grant/getConceptNote", v.$route.params.cnote);
      //get the grant note
      v.$store.dispatch("Grant/getGrantNote", v.$root.decryptRoute(to).params);
    });
  },
  computed: {
    decryptedRoute() {
      return this.$root.decryptRoute(this.$route);
    },
    user() {
      return AuthService.user;
    },
    applications() {
      return this.$store.getters["Application/applicationGetters"](
        "applications"
      );
    },
    singleGrant: function () {
      return this.$store.getters["Grant/grantGetters"]("singleGrant");
    },
    routeParams() {
      //console.log(this.$route.params.no);
      return this.$route.params.no;
    },
    // conceptNoteDoc() {
    //   return this.$store.getters["Grant/grantGetters"]("conceptNote");
    // },
    grantNoteDoc() {
      return this.$store.getters["Grant/grantGetters"]("grantNote");
    },
  },
  methods: {
    isEmpty() {
      const obj = this.singleGrant;
      return Object.keys(obj).length === 0;
    },
    getStatus(val) {
      if (val === "Open") {
        return "Open for application";
      } else {
        return val;
      }
    },
    checkIfGrantIsApplied: function () {
      return this.applications.some(
        (a) =>
          a.grant === this.singleGrant.no &&
          a.applicant === this.user.profile.no
      );
    },

    redirect() {
      this.$router.back();
    },
    openDialog(grant) {
      this.$confirm.show({
        title: "Create Application",
        text: `Are you sure you want to apply for grant ${grant.no}?`,
        onConfirm: () => {
          if (AuthService.check()) {
            this.$store.dispatch("Application/saveApplication", {
              grant: grant.no,
              title: grant.title,
              applicant: this.user.profile.no,
            });
          } else {
            this.$router.push({
              name: "login",
              query: { no: btoa(grant.no) },
            });
          }
        },
      });
    },
  },
};
</script>
<style scoped>
div.bordered-light {
  border: 1px solid #81c784;
  border-bottom: none;
}
span.base-border::after {
  content: "";
  border: 4px solid #000;
}
ol {
  list-style: lower-roman inside;
}
/* .v-card{
  background: #fafafa;
} */
</style>
