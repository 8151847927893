<template>
  <v-container>
    <v-card v-if="!isEmpty()" class="mt-n2" flat>
      <v-card-title>
        {{ specificGrant ? specificGrant.title : "N/A" }}
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters class="caption">
          <v-col cols="12" md="6">
            Application Code:
            <span class="black--text font-italic font-weight-black ml-1">
              {{ specificGrant.no }}
            </span>
          </v-col>
          <v-col cols="12" md="6">
            Status:
            <span
              class="green--text font-italic font-weight-black caption ml-1"
            >
              {{ specificGrant.status }}
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-row mt-2">
            Granted:
            <v-switch
              class="mt-0 ml-2 pt-0"
              :input-value="specificGrant.granted"
              inset
              readonly
              flat
              dense
            ></v-switch>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-row mt-2">
            <v-btn
              class="mx-2 text-body-2 text-capitalize"
              color="success"
              v-if="specificGrant.status === 'New'"
              @click="submitApplication(specificGrant)"
            >
              submit application
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />
      <section class="scroller">
        <v-card-text v-if="document">
          <h4 class="black--text font-subtitle-1 font-weight-medium">
            Grant Proposal
          </h4>

          <v-card outlined max-height="150" max-width="300" class="my-2">
            <v-list-item>
              <div class="d-flex" style="cursor: pointer" @click="viewFile()">
                <v-list-item-icon>
                  <v-chip label color="blue lighten-4" small>
                    <v-icon small color="blue">mdi-file-outline</v-icon>
                  </v-chip>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title> Grant Proposal.pdf </v-list-item-title>
                </v-list-item-content>
              </div>

              <v-list-item-action>
                <v-icon
                  size="20"
                  @click="
                    downloadBase64File(
                      document,
                      `ProposalForm_${specificGrant.no}`
                    )
                  "
                  >mdi-tray-arrow-down</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </v-card-text>

        <v-divider />
        <v-card-text
          v-if="specificGrant && specificGrant.applicationSections"
          class="mt-n4"
        >
          <h4 class="black--text font-subtitle-1 font-weight-medium">
            Grant Sections
          </h4>

          <v-list dense>
            <template v-for="(section, i) in specificGrant.applicationSections">
              <v-subheader :key="'subheader-' + i">
                {{ section.code }}{{ section.title }}
                <v-spacer></v-spacer>
                {{ section.totalAmount }}
              </v-subheader>

              <v-divider :key="'divider-' + i"></v-divider>

              <v-list-item :key="'list-item-' + i">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ section.contents }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="section.hasLines">
                    <ul
                      class="caption"
                      v-for="line in section.applicationSectionLines"
                      :key="'line-' + line.code"
                    >
                      <li>
                        {{ line.title }}
                        <p>
                          <span class="font-weight-bold text-wrap">{{
                            line.instruction
                          }}</span>
                          <br />
                          {{ line.contents }}
                          {{ line.amount > 0 ? "Amount: " + line.amount : "" }}
                        </p>
                      </li>
                    </ul>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="mx-2 text-body-2 text-capitalize"
            color="success"
            v-if="specificGrant.status === 'New' || (specificGrant.status!== 'Submitted') "
            @click="submitApplication(specificGrant)"
          >
            submit application
          </v-btn>
        </v-card-actions> -->
      </section>
    </v-card>
    <v-card flat>
      <v-skeleton-loader
        v-if="isEmpty()"
        type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
      />
    </v-card>
  </v-container>
</template>
<script>
import FileMixin from "@/packages/dashboard/mixin/FileMixin.js";
import ApplicationMixin from "../ApplicationMixin";
export default {
  name: "selectedGrantCard",
  props: {
    specificGrant: { type: Object, default: () => {} },
    isApply: { type: Boolean, default: true },
  },
  mixins: [FileMixin, ApplicationMixin],
  computed: {
    document() {
      return this.$store.getters["Application/applicationGetters"](
        "proposalForm"
      );
    },
  },
  methods: {
    isEmpty() {
      return (
        this.specificGrant === undefined ||
        Object.keys(this.specificGrant).length === 0
      );
    },
    submitApplication(app) {
      this.$confirm.show({
        title: "Submit Grant Application",
        text: "Are you sure? Once you submit the grant application you won't be able to edit it",
        onConfirm: () => {
          this.$store.dispatch("Application/submitApplication", app);
        },
      });
    },

    viewFile() {
      this.$router.push({
        name: "proposalForm",
        params: { no: this.specificGrant.no },
      });
    },
  },
  watch: {
    specificGrant() {
      if (!this.isEmpty())
        this.$store.dispatch("Application/getDocument", {
          no: this.specificGrant.no,
        });
    },
  },
};
</script>
<style scoped>
@import url("../styles.css");
</style>
