import AppLayout from "@/components/AppLayout.vue";
import GrantApplication from "./components/GrantApplication.vue";
import Application from "./views/Application.vue";
import ApplicationList from "./components/ApplicationList";
import proposalForm from "./components/ProposalForm";

import Auth from "@/router/middleware/Auth";
import FilledConceptNotePopUp from "./components/FilledConceptNotePopUp.vue";
export default [
  {
    path: "/grant-application",
    component: AppLayout,
    children: [
      {
        path: "",
        components: {
          view: Application,
        },
        children: [
          {
            path: "",
            redirect: "/grant-application/card",
          },
          {
            path: "card/:no",
            name: "GrantApplication",
            components: {
              view: GrantApplication,
            },
            middleware: [Auth],
          },
          {
            path: "applicationsList",
            name: "applicationList",
            components: {
              view: ApplicationList,
            },
            middleware: [Auth],
          },
          {
            path: "proposalForm/:no",
            name: "proposalForm",
            components: {
              view: proposalForm,
            },
            middleware: [Auth],
          },
          {
            path: "submit/:no",
            name: "submitConceptNote",
            components: {
              view: FilledConceptNotePopUp,
            },
            middleware: [Auth],
          },
        ],
      },
    ],
  },
];
