export default {
  login: "/auth/login",
  register: "/auth/register",
  passwordReset: "auth/forgot-password",
  setPassword: "auth/setPassword",
  password: "auth/reset-password",
  logout: "auth/logout",
  user: `auth/user`,
  verify: `auth/search`,
  sendOtp: `auth/resend-otp`,
  verifyOtp: `auth/verify-otp`,
  company: "company",
};
