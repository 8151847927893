<template>
  <v-card class="mx-auto" flat>
    <!-- <v-card-title>
      <v-text-field
        v-model="title"
        label="Application Title"
        outlined
        placeholder="Enter Your Grant Proposal Title"
        persistent-placeholder
        prepend-inner-icon="title"
      />
    </v-card-title>
    <v-divider /> -->
    <div v-if="applicationSetup.length">
      <v-expansion-panels
        v-model="expanded"
        accordion
        focusable
        active-class="secondary--text"
        dense
      >
        <v-expansion-panel
          v-for="(item, i) in applicationSetup"
          :key="i"
          :disabled="!item.completed && !isEnabled"
        >
          <!-- && !isEnabled  logic check-->
          <v-expansion-panel-header>
            <div class="d-flex justify-start">
              <v-icon
                color="success"
                class="mx-2"
                v-if="item.completed || isEnabled"
              >
                check_circle
              </v-icon>
              <span
                class="text-capitalize text-body-1 mt-1 font-weight-bold text-truncate"
                style="max-width: 80%"
                >{{ item.code }}{{ item.title }}</span
              >
            </div>
            <v-spacer />

            <v-chip
              class="secondary mx-2 text-capitalize"
              style="max-width: 21%; font-size: 13px"
              label
            >
              Points to be Awarded: {{ item.pointsAwarded }}
            </v-chip>
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <v-progress-linear indeterminate v-show="loading" color="primary" />
            <v-form :ref="item.code" v-model="item.isValid">
              <v-card-text>
                <div v-if="item.conceptOutlineInstruction.length > 0">
                  <v-list dense>
                    <v-list-item
                      v-for="(instruction, i) in item.conceptOutlineInstruction"
                      :key="i"
                      dense
                    >
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap">
                          <span class="mr-2">{{
                            `${instruction.conceptNote}${instruction.code}`
                          }}</span>
                          <span class="text-body-2 font-weight-bold">{{
                            instruction.instruction
                          }}</span>
                        </v-list-item-title>

                        <v-textarea
                          v-if="
                            !instruction.is_Cost_Allocation &&
                            instruction.type !== 'Project Team'
                          "
                          v-model="instruction.contents"
                          outlined
                          dense
                          rows="3"
                          placeholder="Enter your response here..."
                          persistent-placeholder
                          class="mt-2 py-n3"
                          :maxLength="item.maximum === 0 ? null : item.maximum"
                          :rules="getRules(item)"
                        />

                        <v-text-field
                          v-model="instruction.amount"
                          v-if="instruction.is_Cost_Allocation"
                          label="Section Amount"
                          outlined
                          dense
                          placeholder="Enter your response here..."
                          persistent-placeholder
                          type="number"
                          min="0"
                          @keypress="filter($event)"
                          :rules="[(v) => !!v || 'Please enter amount']"
                        />
                      </v-list-item-content>
                    </v-list-item>
                    <!-- user uploads -->
                    <specific-section-uploads
                      :attachments="attachments"
                      :section="item.code"
                      :attachmentMandatory="item.attachmentMandatory"
                      @addAttachment="addAttachment"
                    />
                  </v-list>
                </div>
                <div v-else-if="!item.isAttachmentSection">
                  <v-textarea
                    v-model="item.contents"
                    outlined
                    dense
                    rows="3"
                    placeholder="Enter your response here..."
                    persistent-placeholder
                    class="mt-2 py-n3"
                    :maxLength="item.maximum === 0 ? null : item.maximum"
                    :rules="getRules(item)"
                  />
                  <!-- user uploads -->
                  <specific-section-uploads
                    :attachments="attachments"
                    :section="item.code"
                    @addAttachment="addAttachment"
                  />
                </div>
                <div v-else>
                  <Attachments
                    class="mt-n2"
                    :attachments="attachments"
                    :line="application"
                    @addAttachment="addAttachment"
                    @removeAttachment="removeAttachment"
                  />
                </div>
                <!-- check if co-investigator is required -->
                <div v-if="item.type === 'Co-Investigators'">
                  <CoInvestigators
                    :is-edit="true"
                    :investigators="coInvestigators"
                    @save="saveCoInvestigatorsData"
                    @delete="deleteCoInvestigator"
                  />
                </div>
                <ProjectTeam
                  v-if="item.type === 'Project Team'"
                  :is-edit="true"
                  :projectTeam="projectTeamMembers"
                  @add-member="saveProjectTeamProfile"
                  @edit-member="editProjectTeamProfile"
                  @delete-profile="deleteProjectTeamProfile"
                />
              </v-card-text>
            </v-form>
            <v-card-actions>
              <!-- previous -->
              <v-btn
                class="primary text-capitalize"
                @click="previous(item)"
                v-if="isPrevious"
                text
              >
                <v-icon left>arrow_back</v-icon>
                Previous
              </v-btn>
              <v-spacer />
              <!-- <v-btn
                v-if="isSubmit"
                class="primary text-capitalize"
                :loading="$store.getters['Dashboard/loading']"
                :disabled="$store.getters['Dashboard/loading']"
                @click="saveApplication(item)"
                text
                >Save Application
                <v-icon right>save</v-icon>
              </v-btn> -->

              <!-- submit -->
              <v-btn
                v-if="isSubmit"
                class="primary text-capitalize"
                :loading="$store.getters['Dashboard/loading']"
                :disabled="$store.getters['Dashboard/loading']"
                @click="submitApplicationHandler()"
                text
                >Submit Application
                <v-icon right>add_task</v-icon>
              </v-btn>

              <!-- next -->
              <v-btn
                v-else
                class="primary text-capitalize"
                @click="saveApplication(item)"
                text
              >
                Next
                <v-icon right>arrow_forward</v-icon>
              </v-btn>
            </v-card-actions>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-else>
      <v-skeleton-loader type="list-item-three-line" class="mx-auto" />
      <v-skeleton-loader type="list-item-three-line" class="mx-auto" />
      <v-skeleton-loader type="list-item-three-line" class="mx-auto" />
    </div>
  </v-card>
</template>
<script>
import { AuthService } from "@/packages/dashboard/modules/auth";
import ApplicationMixin from "../ApplicationMixin";
import Attachments from "./Attachments";
import CoInvestigators from "./CoInvestigators.vue";
import ProjectTeam from "./ProjectTeam.vue";
import SpecificSectionUploads from "./SpecificSectionUploads.vue";

export default {
  name: "ApplicationForm",
  props: {
    grant: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [ApplicationMixin],
  components: {
    Attachments,
    CoInvestigators,
    ProjectTeam,
    SpecificSectionUploads,
  },
  data: function () {
    return {
      expanded: 0,
      loading: false,
      attachments: [],
      dialog: false,
      isSavingInvestigators: false,
    };
  },

  computed: {
    applicationSetup: function () {
      return this.$store.getters["Setup/setupGetters"]("applicationSetup");
    },
    isPrevious: function () {
      return this.expanded > 0;
    },
    user() {
      return AuthService.user;
    },
    isSubmit() {
      return this.expanded === this.applicationSetup.length - 1;
    },
    isEdit() {
      return this.$route.query.no;
    },
    isEnabled(item) {
      if (this.isEdit) {
        item.completed = true;
        return true;
      }
      return false;
    },
    application() {
      return this.$store.getters["Application/applicationGetters"](
        "application"
      );
    },
    //get CoInvestigators
    coInvestigators() {
      return this.$store.getters["Application/applicationGetters"](
        "coInvestigators"
      );
    },
    //project team members
    projectTeamMembers() {
      return this.$store.getters["Application/applicationGetters"](
        "projectTeamMembers"
      );
    },
    /**check lists*/
    checklists() {
      return this.$store.getters["Setup/setupGetters"]("checklists");
    },
  },
  methods: {
    saveApplication(item) {
      this.$store.dispatch("Application/updateApplication", {
        applicationSectionLines: item.conceptOutlineInstruction.map((x) => ({
          ...x,
          section: item.code,
          code: x.code,
          instruction: x.instruction,
          contents: x.contents,
          amount: parseFloat(x.amount),
        })),
        no: this.application.no,
        section: item.code,
        amount: parseFloat(item.amount ?? 0),
        attachments: [...this.attachments],
      });

      // const data = this.applicationSetup.map((el) => {
      //   return this.formatData(el);
      // });
      // if (this.isEdit) {
      //   const application = { ...this.application };
      //   // delete application.applicationSections;
      //   // delete application.SystemCreatedAt;
      //   // delete application.SystemModifiedAt;
      //   this.$store.dispatch("Application/updateApplication", {
      //     ...application,
      //     attachments: [...this.attachments],
      //     applicationSections: [...data],
      //   });
      // } else {
      //   this.$store.dispatch("Application/saveApplication", {
      //     grant: this.$root.decryptRoute(this.$route).params.no,
      //     title: this.grant.title,
      //     applicant: this.user.profile.no,
      //     attachments: [...this.attachments],
      //     applicationSections: [...data],
      //   });
      // }

      //move to next section
      if (!this.isSubmit) this.next(item);
    },
    addAttachment(attachment) {
      this.attachments.push({ ...attachment });
    },
    removeAttachment(attachment) {
      this.attachments.splice(
        this.attachments.findIndex((a) => a.name === attachment.name),
        1
      );
    },
    //edit a section
    editSection(index) {
      if (this.isEdit) {
        this.expanded = null; //reset
        this.expanded = index;
      }
      return this.expanded;
    },
    submitApplicationHandler() {
      //save all application data first
      this.$store.dispatch("Application/updateApplication", {
        attachments: [...this.attachments],
        no: this.application.no,
      });

      this.$root.routeTo({
        name: "submitConceptNote",
        params: { no: this.application.no },
      });
    },
    //func ()-> handle re-routing
    reRouteToAppSummary() {
      this.$root.routeTo({
        name: "submitConceptNote",
        params: { no: this.application.no },
      });
    },
    // fn ()=> rules
    getRules(item) {
      const rules = [(v) => !!v || "Please enter a response"];

      if (item.maximum !== 0) {
        rules.push((v) => {
          const wordCount = v ? v.toString().split(/\s+/).length : 0;
          return (
            wordCount <= item.maximum ||
            `Maximum length is ${item.maximum} words`
          );
        });
      }

      return rules;
    },

    //fn () save CoInvestigator
    saveCoInvestigatorsData(investigator) {
      // Check if documentNo is empty and update it
      const decryptedRoute = this.$root.decryptRoute(this.$route);
      const documentNo = decryptedRoute.query.no;

      if (!investigator.documentNo) {
        investigator.documentNo = documentNo;
      }
      investigator.type = "Co-Investigator";

      this.$store.dispatch("Application/saveCoInvestigators", investigator);
    },
    //delete investigator
    deleteCoInvestigator() {
      this.$store.dispatch("Application/getCoInvestigators", {
        no: this.$root.decryptRoute(this.$route).query.no,
      });
    },

    /**fn () project team*/
    saveProjectTeamProfile(projectTeam) {
      const documentNo = this.$root.decryptRoute(this.$route).query.no;

      if (!projectTeam.documentNo) {
        projectTeam.documentNo = documentNo;
      }

      projectTeam.type = "Team-Member";

      this.$store.dispatch("Application/saveProjectTeam", projectTeam);
    },
    editProjectTeamProfile() {},
    deleteProjectTeamProfile() {
      this.$store.dispatch("Application/getProjectTeam", {
        no: this.$root.decryptRoute(this.$route).query.no,
      });
    },
    /**fn () attachment checker*/
    isAttachmentRequired(checklistCode, data) {
      const checklistItem = data.find((item) => item.section === checklistCode);
      if (checklistItem && checklistItem.type === "Attachment") {
        return checklistItem.mandatory === true;
      }
      return false;
    },
  },
  watch: {
    application: {
      handler: function (val) {
        if (val) {
          this.attachments = [...val.attachments];
          this.applicationSetup.map((el) => {
            if (val.applicationSections.length > 0) {
              const applicationSection = val.applicationSections.find(
                (x) => x.code === el.code
              );
              if (!applicationSection) return el;
              const applicationSectionLines = applicationSection
                ? applicationSection.applicationSectionLines
                : [];
              delete applicationSection.applicationSectionLines;
              // merge el to applicationSection
              Object.assign(el, applicationSection);
              // merge el.conceptOutlineInstructions to applicationSectionLines
              if (el.conceptOutlineInstruction.length > 0)
                el.conceptOutlineInstruction.map((instruction) => {
                  const applicationSectionLine = applicationSectionLines.find(
                    (x) => x.code === instruction.code
                  );
                  if (applicationSectionLine) {
                    Object.assign(instruction, applicationSectionLine);
                  }
                });

              return el;
            }
          });
        }
      },
      deep: true,
    },
  },
};
</script>
