import AuthLayout from "./views/AuthLayout.vue";
import Login from "./components/Login.vue";
import SignUp from "./components/SignUp.vue";
import ForgotPassword from "./components/ForgotPassword";
import Verify from "./components/Verify";
import PasswordReset from "./components/PasswordReset";

export default [
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        path: "",
        redirect: "/auth/login",
      },
      {
        path: "login",
        name: "login",
        components: {
          view: Login,
        },
      },
      {
        path: "register/:code?",
        name: "Signup",
        components: {
          view: SignUp,
        },
      },
      {
        path: "forgot-password",
        name: "forgotPassword",
        components: { view: ForgotPassword },
      },
      {
        path: "password/:code",
        name: "setPassword",
        components: { view: PasswordReset },
      },

      {
        path: "verify",
        name: "Verify",
        components: {
          view: Verify,
        },
      },
    ],
  },
];
