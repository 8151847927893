import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
/** misc npm packages */
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueEllipseProgress from "vue-ellipse-progress";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

/** global mixins */
import {
  listener,
  FileMixin,
  RouteMixin,
  DateMixin,
} from "@/packages/dashboard/mixin";
/** app plugins */
import vuetify from "./plugins/vuetify";
import appAlert from "@/packages/dashboard/plugins/appAlert";
import { filters } from "@/packages/dashboard/utils";
import confirmation from "@/packages/dashboard/plugins/confirmation";
/** app packages */
import Dashboard from "@/packages/dashboard";
import Application from "@/packages/application";
import Setup from "@/packages/setup";
import Profile from "@/packages/profile";

Vue.config.productionTip = false;

window.Event = new Vue();

const options = {
  store,
  router,
};

filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

Vue.use(VueEllipseProgress);
Vue.use(Toast);
Vue.use(confirmation);
Vue.use(appAlert);
Vue.use(VueI18n);
Vue.use(VueTelInputVuetify, { vuetify });

Vue.use(Dashboard, options);
Vue.use(Application, options);
Vue.use(Setup, options);
Vue.use(Profile, options);

new Vue({
  router,
  store,
  vuetify,
  mixins: [listener, FileMixin, RouteMixin, DateMixin],
  render: (h) => h(App),
}).$mount("#app");
