<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card class="rounded-lg px-5">
      <v-card-title class="text-h5pt-0">
        <v-row no-gutters>
          <v-col cols="12" md="11" class="d-flex flex-column text-center">
            <v-avatar size="120" class="mx-auto">
              <v-img
                contain
                :src="`data:application/jpg;base64,${company.base64Logo}`"
              />
            </v-avatar>
            <span class="mt-1 text-capitalize">Login to Your Account</span>
          </v-col>
          <v-col cols="12" md="1">
            <v-btn icon color="accent" @click="$router.back()">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pt-6">
        <v-form ref="loginForm" v-model="isValid">
          <v-text-field
            v-model="formData.email"
            label="Email"
            type="text"
            outlined
            dense
            :rules="rules.email"
            @keyup.enter="loginHandler()"
          />
          <v-text-field
            v-model="formData.password"
            label="Password"
            type="password"
            @copy.prevent
            outlined
            @keyup.enter="loginHandler()"
            :rules="rules.password"
            autocomplete="false"
            dense
          />
          <div class="d-flex">
            <!-- forgot link -->
            <router-link
              :to="{ name: 'forgotPassword' }"
              class="mt-1 text-decoration-none"
            >
              Forgot Password?
            </router-link>
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions class="text-center d-flex flex-column">
        <v-spacer></v-spacer>
        <v-btn
          @click="loginHandler"
          :disabled="$store.getters['Dashboard/loading']"
          block
          @keyup.enter="loginHandler"
          color="secondary"
          rounded
        >
          Login
        </v-btn>
        <v-sheet height="80" class="py-6">
          Don't have an account?
          <router-link
            :to="{ name: 'Signup', query: { no: $route.query.no } }"
            class="mt-1 text-decoration-none"
          >
            Sign Up
          </router-link>
        </v-sheet>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AuthService from "../AuthService";
export default {
  name: "Login",
  props: {
    //
  },
  mixins: [
    //
  ],
  components: {
    //
  },
  data: function () {
    return {
      //handle form input
      formData: {
        email: "",
        password: "",
      },
      dialog: true,
      isValid: false,
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   next((v) => {
  //     //
  //   });
  // },
  mounted() {
    //
    Event.$on("login-form", (state) => {
      this.dialog = state;
    });
    if (process.env.VUE_APP_ENABLE_LOGIN === "true" && AuthService.check()) {
      //@ todo- redirect to /grant/:refNo/apply application page
      // this.$router.replace({ name: "GrantLists" });
    }
  },
  computed: {
    //validation rules for the login form
    rules() {
      return {
        email: [
          (v) => !!v || "Email is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        password: [(v) => !!v || "Password is required"],
      };
    },
    //get company info
    company() {
      return this.$store.getters["Auth/company"];
    },
  },
  methods: {
    //login handler
    loginHandler: function () {
      this.$refs.loginForm.validate();
      if (this.isValid) {
        this.$store.dispatch("Auth/login", this.formData);
      }
    },
  },
  watch: {
    //
  },
};
</script>
<style>
@import url("../styles.css");
</style>
