import { call } from "@/service";
import { GrantConstants as constants } from "./index";

export default {
  namespaced: true,
  state: {
    loading: false,
    grants: [],
    allGrants: [],
    singleGrant: {},
    profile: {},
    company: {},
    grantApprovals: {},
    grantStatus: {},
    applications: [],
    conceptNote: "",
    grantNote: "",
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    grantGetters: (state) => (val) => state[val],
    profile: (state) => state.profile,
    company: (state) => state.company,
  },
  actions: {
    getGrants: ({ commit }, no) => {
      commit("MUTATE", { state: "loading", value: true });
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", constants.grants(no))
        .then((res) => {
          commit("MUTATE", { state: "loading", value: false });
          commit("MUTATE", { state: "grants", value: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getAllGrants: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });

      call("get", constants.allGrants)
        .then((res) => {
          commit("MUTATE", { state: "allGrants", value: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getSingleGrant({ commit }, payload) {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constants.SINGLEGRANT, payload)
        .then((res) => {
          commit("MUTATE", { state: "singleGrant", value: res.data.data[0] });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getGrant: ({ commit }, no) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", constants.SINGLEGRANT, { no: no })
        .then((res) => {
          commit("MUTATE", { state: "singleGrant", value: res.data.data[0] });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    getConceptNote: ({ commit }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constants.conceptNote, payload)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "conceptNote", value: res.data.data[0] });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    getGrantNote: ({ commit }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constants.grantNote, payload)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "grantNote", value: res.data.data[0] });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
  },
};
