<template>
  <v-card class="mx-auto mb-3" flat>
    <v-card-title class="text-body-2">
      <p
        class="red--text font-italic caption pa-3"
        v-if="mandatoryDocs.length !== 0"
      >
        <v-icon color="red" class="font-bold">mdi-alert</v-icon>
        Mandatory Documents
        <span class=""
          >(click to upload or ensure it is on this list, before
          submitting)</span
        >

        <v-chip-group column>
          <v-chip
            small
            class="ml-4 mt-n1"
            color="success"
            v-for="doc in mandatoryDocs"
            :key="doc.code"
            @click="attachFile(doc.code)"
          >
            <v-icon left> mdi-folder-file </v-icon>
            {{ doc.description }}
          </v-chip>
        </v-chip-group>
      </p>
      <!-- {{ isEdit ? "Uploaded" : "" }} Attachments -->
      <v-spacer />
      <v-btn
        class="primary text-capitalize"
        v-if="isEdit"
        rounded
        @click="attachFile()"
        >Add any other
        <v-icon right>attach_file</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider />
    <v-data-table
      :headers="headers"
      :items="attachments"
      class="elevation-0"
      dense
      disable-pagination
      hide-default-footer
      hide-default-header
    >
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.no="{ item }">
        <span v-if="item.no">{{ item.no }}</span>
        <span v-else>{{ item.fileName }}</span>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <div class="d-flex px-2">
            <v-btn
              rounded
              small
              class="primary text-capitalize mx-2"
              @click="downloadBase64File(item.attachment, item.fileName)"
            >
              Download
              <v-icon right>download</v-icon>
            </v-btn>
            <v-btn
              rounded
              small
              v-if="isEdit"
              class="error text-capitalize"
              @click="deleteAttachment(item)"
            >
              Delete
              <v-icon right>delete</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
    <input
      type="file"
      ref="file"
      @change="uploadFile"
      hidden
      accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/*"
    />
  </v-card>
</template>
<script>
import { FileMixin } from "@/packages/dashboard/mixin";
import { tempUrl } from "@/environment/environment";
import { helper } from "@/packages/dashboard/utils";
export default {
  name: "Attachments",
  mixins: [FileMixin],
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    line: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      tempUrl: tempUrl,
      section: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          value: "no",
        },
        {
          text: "fileName",
          value: "fileName",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ];
    },
    /**check lists*/
    checklists() {
      return this.$store.getters["Setup/setupGetters"]("checklists");
    },
    mandatoryDocs: function () {
      return this.checklists.filter(
        (checklist) =>
          checklist.type === "Attachment" && checklist.mandatory === true
      );
    },
  },
  methods: {
    deleteAttachment(attachment) {
      if (attachment.tableID) {
        this.$confirm.show({
          title: "Delete Attachment",
          text: "Are you sure you want to delete this attachment? This action cannot be undone.",
          onConfirm: () => {
            this.$store.dispatch("Application/deleteAttachment", {
              // attachment: attachment,
              // line: this.line,
              id: attachment.id,
              documentType: attachment.documentType,
              lineNo: attachment.lineNo,
              no: attachment.no,
              tableID: attachment.tableID,
            });

            //remove from table
            this.$emit("removeAttachment", attachment);
          },
        });
      } else {
        this.$emit("removeAttachment", attachment);
      }
    },
    attachFile(section="") {
      this.$refs.file.click();
      this.section = section;
      console.log("passed section", this.section);
    },
    async uploadFile(e) {
      const file = e.target.files[0];
      if (file) {
        let base64File = await helper.toBase64(file);
        base64File = base64File.split(",")[1];
        this.$emit("addAttachment", {
          attachment: base64File,
          fileName: file.name,
          section: this.section ?? "",
        });
      }
      //reset the section
      this.section = "";
    },
  },
};
</script>
