<template>
  <v-app>
    <v-main>
      <router-view name="view" />
      <router-view name="action" />
    </v-main>
    <spinner />
    <app-alert />
    <app-footer />
    <confirmation />
  </v-app>
</template>
<script>
import Spinner from "../../../plugins/loader/views/Spinner.vue";
import AuthService from "../AuthService";
import AppFooter from "@/components/AppFooter.vue";
export default {
  name: "AuthLayout",
  components: { Spinner, AppFooter },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        if (v.$route.query.no) {
          v.$router.push({
            name: "GrantApplication",
            params: {
              no: v.$route.query.no,
            },
          });
        } else {
          v.$router.push({
            name: "GrantLists",
          });
        }
      }
    });
  },
  computed: {
    auth() {
      return AuthService;
    },
    company() {
      return this.$store.getters["Grant/company"];
    },
  },
};
</script>
<style>
@import url("../styles.css");
</style>
