import call from "@/service/http";
import appConstants from "./appConstants";
import { AuthService } from "../dashboard/modules/auth";

export default {
  namespaced: true,
  state: {
    applications: [],
    application: {},
    proposalForm: "",
    coInvestigators: [],
    projectTeamMembers: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    applicationGetters: (state) => (val) => state[val],
  },
  actions: {
    getApplications: ({ commit }, params) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", appConstants.applications(params))
        .then((res) => {
          commit("MUTATE", { state: "applications", value: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getApplication: ({ commit }, params) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", appConstants.applications(params))
        .then((res) => {
          commit("MUTATE", { state: "application", value: res.data.data[0] });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    saveApplication: ({ commit, dispatch }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", appConstants.saveApplication, payload)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Application created, please proceed");
          dispatch("getApplication", {
            applicant: AuthService.user.profile.no,
            no: res.data.data[0].no,
          });
          Event.$emit("route-application-reload", {
            name: "GrantApplication",
            params: { no: res.data.data[0].grant },
            query: { no: res.data.data[0].no },
          });
          //Event.$emit('route-change', { name: 'applicationList' });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    updateApplication: ({ commit, dispatch }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      //console.log(`payload for update ${JSON.stringify(payload)}`);
      call("post", appConstants.updateApplication, payload)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          //Event.$emit('route-change', { name: 'applicationList' });

          dispatch("getApplication", {
            applicant: AuthService.user.profile.no,
            no: payload.no,
          });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    deleteApplication: ({ commit, dispatch }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      //console.log(`delete payload ${JSON.stringify(payload)}`);
      call("post", appConstants.deleteApplication, payload)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Grant Application successfully deleted");
          dispatch("getApplications", {
            applicant: AuthService.user.profile.no,
          });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    submitApplication: ({ commit, dispatch }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });

      call("post", appConstants.submitApplication, payload)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Grant Application Submitted Successfully");
          dispatch("getApplications", {
            applicant: AuthService.user.profile.no,
          });
          Event.$emit("route-change", { name: "applicationList" });
        })
        .catch((err) => {
          console.log(err);
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    getDocument: ({ commit }, payload) => {
      // commit('Dashboard/SET_LOADING', true, { root: true });
      call("post", appConstants.propsalForm, payload)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "proposalForm", value: res.data.data[0] });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    //save CoInvestigator
    saveCoInvestigators: ({ commit, dispatch }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });

      call("post", appConstants.saveCoInvestigators, payload) //
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });

          dispatch("getCoInvestigators", {
            no: res.data.data[0].documentNo,
          });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    //get Investigators
    getCoInvestigators: ({ commit }, params) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", appConstants.getCoInvestigator(params))
        .then((res) => {
          commit("MUTATE", { state: "coInvestigators", value: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    deleteCoInvestigator: ({ commit }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      //console.log(`delete payload ${JSON.stringify(payload)}`);
      call("post", appConstants.deleteCoInvestigator, payload)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Investigator deleted");
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    /**fn () save team profile */
    saveProjectTeam: ({ commit, dispatch }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });

      call("post", appConstants.saveProjectTeamProfile, payload) //
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });

          dispatch("getProjectTeam", {
            no: res.data.data[0].documentNo,
          });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    /**fn () get project team profiles */
    getProjectTeam: ({ commit }, params) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", appConstants.getProjectTeam(params))
        .then((res) => {
          commit("MUTATE", {
            state: "projectTeamMembers",
            value: res.data.data,
          });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    deleteProjectTeam: ({ commit }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });

      call("post", appConstants.deleteProjectTeamMember, payload)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Profile deleted");
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    /* ()-> delete attachments*/
    deleteAttachment: ({ commit }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", appConstants.deleteAttachment, payload)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Attachment deleted");
          // dispatch("getApplications", {
          //   applicant: AuthService.user.profile.no,
          // });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    //reopen application
    reopenApplication: ({ commit, dispatch }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", appConstants.reopenApplication, payload)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiSuccess",
            `Reopened Document ${payload.no} successfully`
          );
          dispatch("getApplications", {
            applicant: AuthService.user.profile.no,
          });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
  },
};
