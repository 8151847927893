<!-- eslint-disable no-extra-boolean-cast -->
<template>
  <v-card elevation="0">
    <v-card-title> Contacts </v-card-title>
    <v-form v-model="isValid" ref="contactForm" class="mt-n4">
      <v-card-text class="">
        <v-row dense>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formData.email"
              :rules="rules.Field"
              ref="email"
              dense
              outlined
            >
              <template v-slot:label>
                <div>Email <span class="red--text">*</span></div>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4"
            ><v-text-field
              v-model="formData.phoneNo"
              :rules="rules.Field"
              ref="phoneNo"
              type="text"
              v-on:keypress="NumbersOnly"
              dense
              outlined
            >
              <template v-slot:label>
                <div>Phone <span class="red--text">*</span></div>
              </template>
            </v-text-field>
          </v-col>
          <!-- country -->
          <v-col cols="12" md="4">
            <!-- <v-text-field
              v-model="formData.country"
              :rules="rules.Field"
              ref="country"
              dense
              outlined
            > </v-text-field> -->
            <v-select
              dense
              outlined
              :items="['KE', 'UG', 'TZ', 'SA']"
              v-model="formData.country"
              :rules="rules.Field"
              ref="country"
              @input="filterCounties($event)"
            >
              <template v-slot:label>
                <div>Country <span class="red--text">*</span></div>
              </template>
            </v-select>
          </v-col>
          <!-- county -->
          <v-col cols="12" md="4">
            <v-select
              dense
              outlined
              :items="filteredCounties"
              :item-value="(item) => item.countyNo"
              :item-text="(item) => item.countyName"
              v-model="formData.county"
              :rules="rules.Field"
              ref="county"
            >
              <template v-slot:label>
                <div>County <span class="red--text">*</span></div>
              </template>
            </v-select>
          </v-col>
          <!-- city -->
          <v-col cols="12" md="4"
            ><v-text-field
              v-model="formData.city"
              :rules="rules.Field"
              ref="city"
              dense
              outlined
            >
              <template v-slot:label>
                <div>city <span class="red--text">*</span></div>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="4"
            ><v-text-field
              v-model="formData.address"
              :rules="rules.Field"
              ref="address"
              dense
              outlined
            >
              <template v-slot:label>
                <div>Address <span class="red--text">*</span></div>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formData.postCode"
              :rules="rules.Field"
              ref="postCode"
              dense
              outlined
            >
              <template v-slot:label>
                <div>P.O. Box <span class="red--text">*</span></div>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <Spinner />
  </v-card>
</template>

<script>
import { AuthService } from "@/packages/dashboard/modules/auth";
import Spinner from "@/packages/dashboard/plugins/loader/views/Spinner.vue";

export default {
  name: "Contact",
  components: { Spinner },

  data() {
    return {
      type: "",
      edit: true,
      isValid: false,
      formData: {
        no: "",
        phoneNo: "",
        email: "",
        address: "",
        postCode: "",
        city: "",
        type: "Personal",
        country: "",
        county: "",
      },
      filteresCounties: [],
    };
  },

  computed: {
    rules() {
      return {
        Field: [(v) => !!v || "Field is required"],
      };
    },
    user() {
      return AuthService.user;
    },
    contact() {
      return this.$store.getters["Profile/contactGetters"]("contact");
    },
    counties() {
      return this.$store.getters["Setup/setupGetters"]("counties");
    },
  },

  methods: {
    saveContact: function () {
      if (!this.isValid) {
        this.$refs.contactForm.validate();
      } else {
        delete this.formData.status;
        this.formData.no = this.user.profileID;
        this.$store.dispatch("Profile/saveContact", this.formData);
      }
    },
    checkContact() {
      if (this.contact.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    editContact() {
      if (!this.isValid) {
        this.$refs.contactForm.validate();
      } else {
        this.formData.no = this.user.profileID;
        delete this.lastModifiedTime;
        this.$store.dispatch("Profile/editContact", this.formData);
      }
    },
    updateProfileContact() {
      if (this.formData.versionNo) {
        this.editContact();
      } else {
        this.saveContact();
      }
    },
    redirect() {
      this.$router.push({
        name: "Profile",
      });
    },
    filterCounties(event) {
      this.filteredCounties = this.counties.filter(
        (el) => el.countryCode === event
      );
    },
  },

  watch: {
    contact: {
      handler: function () {
        if (this.contact.length > 0) {
          const contact = { ...this.contact[0] };
          Object.keys(this.formData).forEach((key) => {
            if (contact[key] === contact[key]) {
              this.formData[key] = contact[key];
            }
          });
          this.formData.versionNo = contact.versionNo;
          this.formData.entryNo = contact.entryNo;
        }
        if (this.contact.length === 0) {
          const phoneStr = this.formData.phoneNo;
          if (/^[A-Za-z0-9]*$/.test(phoneStr)) {
            this.formData.phoneNo = "";
            this.formData.email = this.user.email;
          } else {
            this.formData.phoneNo = this.user.phone;
            this.formData.email = this.user.email;
          }
        }
      },
    },
    "formData.country": {
      handler: function () {
        this.filterCounties(this.formData.country);
      },
    },
  },
};
</script>

<style scoped></style>
<!--  -->
