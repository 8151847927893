<template>
  <v-container fluid style="width: 90%">
    <div class="mt-0 mb-10 pb-10">
      <AboutNrfGrants />
      <v-card class="my-6" flat>
        <v-card-text class="m-0 p-0">
          <v-row align="center">
            <v-col cols="12" class="m-0">
              <v-text-field
                outlined
                label="Search for Grants by(Title, Status, ref No)"
                color="primary"
                prepend-inner-icon="mdi-magnify"
                ref="search"
                v-model="search"
              />
            </v-col>
            <!-- <v-col cols="6">
                <div class="d-flex">
                  <v-chip-group active-class="primary" column multiple>
                    <v-chip> Open Grants </v-chip>
                  </v-chip-group>
                </div>
              </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row dense no-gutters>
            <v-col cols="12" md="5" v-if="grants.length !== 0">
              <section class="scroller">
                <v-list>
                  <v-list-item-group color="primary" @change="selectGrants">
                    <template
                      v-for="(item, index) in search.length > 0
                        ? filteredItems
                        : grants"
                    >
                      <v-list-item three-line :key="item.no">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title class="font-weight-bold"
                              >{{ item.title }} –
                              {{ item.no }}</v-list-item-title
                            >
                            <v-list-item-subtitle
                              v-if="item.grantSections.length > 0"
                            >
                              {{ item.grantSections[0].description }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text class="mb-5 primary--text">
                              <v-chip
                                class="ma-2"
                                color="green"
                                text-color="white"
                              >
                                {{ item.no }}
                              </v-chip>
                            </v-list-item-action-text>
                            <v-btn @click="viewGrantNote(item)">
                              View Grant Call Note
                              <v-icon right>mdi-arrow-right</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                      <v-divider
                        v-if="index < grants.length - 1"
                        :key="index"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </section>
              <!-- <v-card
                elevation="24"
                max-width="100%"
                class="mx-auto"
              >
                <v-virtual-scroll
                  :bench="0"
                  :items="search.length > 0
                  ? filteredItems
                  : grants"
                  height="600"
                  item-height="120"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item three-line :key="item.no" @change="selectGrants">
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold"
                          >{{ item.title }} – {{ item.no }}</v-list-item-title
                        >
                        <v-list-item-subtitle v-if="item.grantSections.length > 0">
                          {{ item.grantSections[0].description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text class="mb-5 primary--text">
                          <v-chip
                            class="ma-2"
                            color="green"
                            text-color="white"
                          >
                            {{ item.no }}
                          </v-chip>
                        </v-list-item-action-text>
                        <v-btn
                          :to="{
                            name: 'GrantCard',
                            params: {
                              no: item.no,
                            },
                          }"
                        >
                          View Grant Call Note
                          <v-icon right>mdi-arrow-right</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      
                    </v-list-item>

                    <v-divider></v-divider>
                  </template>
                </v-virtual-scroll>
              </v-card> -->
            </v-col>
            <v-col cols="12" md="5" v-if="loading">
              <v-skeleton-loader
                type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="5" v-if="grants.length === 0 && !loading">
              <v-card-text
                class="d-flex flex-column justify-center fill-height"
              >
                <img
                  src="../../../../../assets/emptyState.svg"
                  class="mx-auto mt-16"
                  alt=""
                  width="300"
                  height="250"
                />
                <p
                  class="text-caption mx-auto text-center mt-5"
                  style="width: 60%"
                >
                  We're sorry, but there are currently no available grants to
                  display at this time. <br />Feel free to refresh or check back
                  later
                </p>
              </v-card-text>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" md="7">
              <SelectedGrantCard
                :specificGrant="selectedGrant ? selectedGrant : grants[0]"
                :loading="loading"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import { AuthService } from "../../auth";
import AboutNrfGrants from "./AboutNrfGrants.vue";
import SelectedGrantCard from "./SelectedGrantCard.vue";
export default {
  name: "GrantLists",
  components: { AboutNrfGrants, SelectedGrantCard },

  data: function () {
    return {
      //index: 0,
      search: "",
      selectedGrant: undefined,
      //statuses: "",
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Grant/getGrants");

      if (AuthService.user && AuthService.user.profile) {
        v.$store.dispatch("Application/getApplications", {
          applicant: AuthService.user.profile.no,
        });
      }
    });
  },
  computed: {
    loading() {
      return this.$store.getters["Grant/grantGetters"]("loading");
    },
    grants: function () {
      return this.$store.getters["Grant/grantGetters"]("grants");
    },
    applicationSetup: function () {
      return this.$store.getters["Setup/setupGetters"]("applicationSetup");
    },
    filteredItems() {
      const data = this.grants.filter((item) => {
        if (!this.search) return this.grants;
        return (
          item.title.toLowerCase().includes(this.search.toLowerCase()) ||
          item.no.toLowerCase().includes(this.search.toLowerCase()) ||
          item.grantStatus.toLowerCase().includes(this.search.toLowerCase())
        );
      });
      return data;
    },
    user() {
      return AuthService.user;
    },
    checkIfUserIsLoggedIn() {
      return AuthService.check();
    },
    indexOfSelectedGrant() {
      return this.grants.findIndex((grant) =>
        grant === this.selectedGrant ? this.selectedGrant.no : this.grants[0].no
      );
    },
  },
  methods: {
    selectGrants(val) {
      if (val >= 0 && val < this.grants.length) {
        this.selectedGrant = this.grants[val];
        console.log(`selected grant ${this.selectedGrant.no}`);
      } else {
        console.error(`Invalid index  -${val} or empty grants array.`);
      }
    },
    viewGrantNote(item) {
      this.$root.routeTo({
        name: "GrantCard",
        params: {
          no: item.no,
        },
      });
    },
  },
};
</script>
<style scoped>
.scroller {
  display: flex !important;
  flex-direction: column;
  height: 70vh;
}
.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.fill-height {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
