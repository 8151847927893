import appStore from "./appStore";
import appRoutes from "./appRoutes";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoutes(appRoutes);
    }

    if (options.store) {
      options.store.registerModule("Application", appStore);
    }
  },
};
