<template>
  <v-container>
    <router-view name="view" />
    <router-view name="action" />
  </v-container>
</template>
<script>
import { AuthService } from "@/packages/dashboard/modules/auth";

export default {
  name: "Profile",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Profile/getProfile");
      v.$store.dispatch("Setup/getCounties");
    });
  },

  computed: {
    auth() {
      return AuthService;
    },
  },
};
</script>
