<template>
  <v-container class="fill-height">
    <v-card elevation="0" class="px-2">
      <v-card-title class="profile--title">
        <div>
          Profile
          <span class="text-capitalize text-body-1">
            &nbsp;{{ user.name }}
          </span>
        </div>

        <v-spacer />
        <v-btn color="primary" @click="editProfile" v-if="checkBio()">
          <v-icon class="mx-2">mdi-circle-edit-outline</v-icon>
          edit
        </v-btn>
        <v-spacer />
        <v-btn
          class="deep-orange lighten-4 deep-orange--text text--darken-4"
          elevation="0"
          @click="redirect()"
        >
          <v-icon class="mx-2">mdi-arrow-left</v-icon>
          Go back
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        <v-row>
          <v-col md="4" sm="12">
            <v-card elevation="0">
              <div class="d-flex justify-center">
                <v-avatar size="140">
                  <v-img
                    v-if="ProfilePicture !== ''"
                    :src="ProfilePicture"
                    height="140px"
                    class="rounded"
                    contain
                  ></v-img>

                  <v-img
                    :src="user.avatar"
                    height="140px"
                    class="rounded"
                    contain
                    v-else-if="user.avatar !== null"
                  />

                  <v-img
                    src="@/assets/NRF-LOGO-LOCKUP-FINAL.png"
                    height="140px"
                    class="rounded"
                    contain
                    v-else
                  />
                </v-avatar>
              </div>
            </v-card>
            <v-divider />
          </v-col>

          <v-divider vertical />

          <v-col md="8" sm="12">
            <v-row dense>
              <v-col md="4" cols="12">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      bio.firstName !== "" ? bio.firstName : "- -"
                    }}</v-list-item-title>
                    <v-list-item-subtitle>First Name</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col md="4">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      bio.secondName !== "" ? bio.secondName : "- -"
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Second Name</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col md="4" cols="12">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ bio.thirdName }}</v-list-item-title>
                    <v-list-item-subtitle>Last Name</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="4">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      bio.maritalStatus
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Marital Status</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col md="4" cols="12">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      bio.IDNo !== "" ? bio.IDNo : "- -"
                    }}</v-list-item-title>
                    <v-list-item-subtitle>ID No</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col md="4" cols="12">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      bio.gender !== "" ? bio.gender : "Unknown"
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Gender</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col md="4" cols="12">
                <v-list-item v-if="bio.personWithDisability">
                  <v-list-item-content>
                    <v-list-item-title>{{ bio.pWDNo }}</v-list-item-title>
                    <v-list-item-subtitle>PWD Number</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col md="4" cols="12">
                <v-list-item v-if="bio.institution">
                  <v-list-item-content>
                    <v-list-item-title>{{ bio.institution }}</v-list-item-title>
                    <v-list-item-subtitle>Institution</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-divider />
            <v-card elevation="0">
              <v-card-title> Contacts </v-card-title>
              <v-row dense>
                <v-col md="4" cols="12">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ bio.email }}</v-list-item-title>
                      <v-list-item-subtitle>Email</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="4" cols="12">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ bio.phoneNo }}</v-list-item-title>
                      <v-list-item-subtitle>Phone</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="4" cols="12">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ bio.address !== "" ? bio.address : "- -" }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Address</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="4" cols="12">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ bio.postCode !== "" ? bio.postCode : "- -" }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Postal Code</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="4" cols="12">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ bio.city ? bio.city : "- -" }}
                      </v-list-item-title>
                      <v-list-item-subtitle>City</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="4" cols="12">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ bio.country ? bio.country : "- -" }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Country</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="4" cols="12">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ bio.county ? bio.county : "- -" }}
                      </v-list-item-title>
                      <v-list-item-subtitle>County</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="profile--actions">
        <!-- <v-spacer/>
        <v-btn color="primary" @click="editProfile" v-if="checkBio()">
          <v-icon class="mx-2">mdi-edit</v-icon>
          Update
        </v-btn> -->
      </v-card-actions>

      <Spinner />
    </v-card>
  </v-container>
</template>
<script>
import { AuthService } from "@/packages/dashboard/modules/auth";
import Spinner from "@/packages/dashboard/plugins/loader/views/Spinner.vue";
export default {
  name: "viewProfile",
  components: { Spinner },
  data: function () {
    return {};
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Profile/getBio");
    });
  },

  computed: {
    user() {
      return AuthService.user;
    },
    bio() {
      return this.$store.getters["Profile/bioGetters"]("bio");
    },

    ProfilePicture() {
      let profile = this.bio;

      if (this.image_url) return this.image_url;

      return profile
        ? profile.b64Image
          ? `data:image/jpeg;base64,${profile.b64Image}` ||
            `data:image/png;base64,${profile.b64Image}`
          : ""
        : "";
    },

    profImage() {
      return `data:image/jpeg;base64,${this.bio.b64Image}`;
    },
  },

  methods: {
    redirect() {
      this.$router.push({
        name: "GrantLists",
      });
    },
    editProfile: function () {
      this.$router.push({ name: "UpdateProfile" });
    },
    checkBio() {
      if (Object.keys(this.bio).length > 0) {
        return true;
      } else {
        return false;
      }
    },
    printBio() {
      console.log(this.bio);
    },
  },
};
</script>
