<template>
  <v-card class="mx-auto mb-3" flat>
    <v-card-title class="text-body-2 font-weight-bold">
      {{ isEdit ? "Edit or Add" : "" }} Co-investigators
      <v-spacer />
      <!-- addCoInvestigator() -->
      <v-btn
        @click="dialog = true"
        class="purple darken-4 white--text text-capitalize"
        v-if="isEdit"
        rounded
        >Add Co-investigator
        <v-icon right>mdi-account-multiple-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="pa-2">
      <v-data-table
        :headers="headers"
        :items="investigators"
        class="elevation-1"
        dense
        disable-pagination
        hide-default-footer
      >
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <div class="d-flex px-2">
              <v-btn
                @click="remove(item)"
                class="mx-2 red darken-4"
                fab
                dark
                small
              >
                <v-icon dark> mdi-account-multiple-remove </v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Add Co-Investigator</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <!-- <v-row>
                <v-col cols="12" sm="6" md="4"> </v-col>
              </v-row> -->
              <v-form ref="coInvestigatorForm" v-model="isValid">
                <v-row dense>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="coInvestigator.fullName"
                      :rules="rules.required"
                      class="mb-2"
                      clearable
                      outlined
                      dense
                      label="Full Name *"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="coInvestigator.institution"
                      :rules="rules.required"
                      class="mb-2"
                      clearable
                      outlined
                      dense
                      label="Institution *"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="coInvestigator.gender"
                      :items="['Male', 'Female']"
                      class="mb-2"
                      outlined
                      dense
                      chips
                      small-chips
                      label="Gender"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="coInvestigator.email"
                      :rules="rules.email"
                      class="mb-2"
                      clearable
                      outlined
                      dense
                      label="Email *"
                    ></v-text-field
                  ></v-col>

                  <v-col cols="12" sm="6" md="4"
                    ><v-text-field
                      v-model="coInvestigator.telephone"
                      :rules="rules.required"
                      class="mb-2"
                      clearable
                      outlined
                      dense
                      label="Phone No *"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="coInvestigator.dateOfBirth"
                      :rules="rules.required"
                      type="date"
                      class="mb-2"
                      clearable
                      outlined
                      dense
                      label="date of birth *"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="coInvestigator.idPassportNo"
                      :rules="rules.required"
                      class="mb-2"
                      placeholder="ID No."
                      outlined
                      dense
                      label="ID No. or Passport *"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-switch
                      v-model="coInvestigator.personWithDisability"
                      inset
                      dense
                      class="mb-2"
                      :label="`Any form of disability`"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="coInvestigator.location"
                      :rules="rules.required"
                      class="mb-2"
                      placeholder="123 main st"
                      outlined
                      dense
                      label="County *"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveCoInvestigators()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>
<script>
//import vueAutoextraUmd from "vue-autoextra";
export default {
  name: "CoInvestigators",
  props: {
    investigators: {
      //type: Array,
      default: () => [],
    },
    line: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    coInvestigator: {
      documentNo: "",
      //lineNo: "",
      fullName: "",
      institution: "",
      gender: "",
      email: "",
      telephone: "",
      idPassportNo: "",
      dateOfBirth: "",
      personWithDisability: false,
      location: "",
    },
    isValid: false,

    //isSaving: false, // Flag to track saving process
    dialog: false,
  }),
  computed: {
    headers() {
      return [
        // {
        //   text: "No.",
        //   align: "start",
        //   value: "documentNo",
        // },
        {
          text: "Full Name",
          value: "fullName",
        },

        {
          text: "institution",
          value: "institution",
        },
        {
          text: "gender",
          value: "gender",
        },
        {
          text: "email",
          value: "email",
        },
        {
          text: "telephone",
          value: "telephone",
        },
        {
          text: "ID No",
          value: "idPassportNo",
        },
        {
          text: "Date of Birth",
          value: "dateOfBirth",
        },
        {
          text: "County",
          value: "location",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ];
    },
    rules() {
      return {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],

        required: [(v) => !!v || "Field is required"],
      };
    },
    //format date
    formattedDateOfBirth() {
      if (this.coInvestigator.dateOfBirth) {
        const date = new Date(this.coInvestigator.dateOfBirth);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return null; //2023-01-01
    },
  },
  methods: {
    remove(investigator) {
      //this.coInvestigators.splice(index, 1);
      this.$store.dispatch("Application/deleteCoInvestigator", {
        lineNo: investigator.lineNo,
        documentNo: investigator.documentNo,
        type: investigator.type,
      });

      this.$emit("delete");
    },
    //save investigator
    saveCoInvestigators() {
      // Validate all text fields and check if they have values

      if (!this.validateInvestigator(this.coInvestigator)) {
        return; // Validation failed, do not proceed
      }
      this.$refs.coInvestigatorForm.validate();
      if (this.isValid) {
        // All fields are valid, emit an event to save the data
        this.$emit("save", this.coInvestigator);
        this.resetFormFields();

        this.dialog = false;
      }
    },
    resetFormFields() {
      for (const key in this.coInvestigator) {
        this.coInvestigator[key] = "";
      }
    },

    validateInvestigator(investigator) {
      // Validate each field of the investigator
      if (
        !investigator.fullName ||
        !investigator.institution ||
        !investigator.gender ||
        !investigator.email ||
        !investigator.telephone ||
        !investigator.dateOfBirth ||
        !investigator.location
      ) {
        return false;
      }
      return true;
    },
  },

  watch: {},
};
</script>
<style scoped>
.last {
  opacity: 0.5;
}
</style>
