<template>
  <v-container class="fill-height">
    <v-card-text>
      <v-skeleton-loader
        type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
      ></v-skeleton-loader
    ></v-card-text>

    <v-dialog v-model="dialog" max-width="75%" persistent>
      <Bio />
    </v-dialog>
  </v-container>
</template>
<script>
import { AuthService } from "@/packages/dashboard/modules/auth";
import Bio from "../modules/bio/Bio.vue";
export default {
  name: "UpdateProfile",
  components: { Bio },
  data: function () {
    return {
      edit: false,
      dialog: true,
      formData: {
        Image: undefined,
        address: "",
        city: "",
        country: "",
        county: "",
        email: "",
        firstName: "",
        secondName: "",
        thirdName: "",
        gender: "",
        IDNo: "",
        institution: "",
        maritalStatus: "",
        no: "",
        personWithDisability: false,
        phoneNo: "",
        postCode: "",
        pWDNo: "",
        anyFormOfDisability: false,
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      editable: false,
      tabs: null,
      headers: [{ title: "Profile", icon: "mdi-account" }],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Profile/getBio");
      v.$store.dispatch("Profile/getContact");
    });
  },

  mounted() {
    Event.$on("updatedProfile", () => {
      this.dialog = false;
    });
  },

  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    user() {
      return AuthService.user;
    },
  },

  methods: {},
};
</script>
