<template>
  <v-card class="mx-auto mb-3" flat>
    <v-card-title class="text-body-2 font-weight-bold">
      <!-- {{ isEdit ? "Edit" : "" }} Profile of the team members. -->
      <v-spacer />

      <v-btn
        @click="dialog = true"
        class="purple darken-4 white--text text-capitalize"
        v-if="isEdit"
        rounded
      >
        <v-icon left>mdi-account-multiple-plus</v-icon>
        Add Project Team
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="pa-2">
      <v-data-table
        :headers="headers"
        :items="projectTeam"
        class="elevation-1"
        dense
        disable-pagination
        hide-default-footer
      >
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <div class="d-flex px-2">
              <v-btn @click="remove(item)" color="red darken-4" plain small>
                <v-icon dark> mdi-account-multiple-remove </v-icon>
                delete
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Profile of the team member.</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="projectTeamForm" v-model="isValid">
                <v-row dense>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="teamProfile.fullName"
                      :rules="rules.required"
                      class="mb-2"
                      clearable
                      outlined
                      dense
                      label="Full Name *"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="teamProfile.institution"
                      :rules="rules.required"
                      class="mb-2"
                      clearable
                      outlined
                      dense
                      label="Institution *"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      v-model="teamProfile.gender"
                      :items="['Male', 'Female']"
                      class="mb-2"
                      outlined
                      dense
                      chips
                      small-chips
                      label="Gender"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="teamProfile.email"
                      :rules="rules.email"
                      class="mb-2"
                      clearable
                      outlined
                      dense
                      label="Email *"
                    ></v-text-field
                  ></v-col>

                  <v-col cols="12" sm="6" md="4"
                    ><v-text-field
                      v-model="teamProfile.telephone"
                      :rules="rules.required"
                      class="mb-2"
                      clearable
                      outlined
                      dense
                      label="Phone No *"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="teamProfile.dateOfBirth"
                      :rules="rules.required"
                      type="date"
                      class="mb-2"
                      clearable
                      outlined
                      dense
                      label="date of birth *"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="teamProfile.idPassportNo"
                      :rules="rules.required"
                      class="mb-2"
                      outlined
                      dense
                      label="ID No. or Passport *"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-switch
                      v-model="teamProfile.personWithDisability"
                      inset
                      dense
                      class="mb-2"
                      :label="`Any form of disability`"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="teamProfile.location"
                      :rules="rules.required"
                      class="mb-2"
                      outlined
                      dense
                      label="County *"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="teamProfile.primaryRole"
                      :rules="rules.required"
                      class="mb-2"
                      outlined
                      dense
                      label="Primary Role *"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="teamProfile.qualifications"
                      :rules="rules.required"
                      class="mb-2"
                      outlined
                      dense
                      label="Qualifications *"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-file-input
                      ref="file"
                      outlined
                      dense
                      counter
                      multiple
                      small-chips
                      truncate-length="10"
                      class="mb-2"
                      label="Attachments"
                      @change="uploadFile"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small
              ><span style="color: #c62828">*</span> indicates required
              field</small
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-3" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveProjectTeamProfile()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>
<script>
import { FileMixin } from "@/packages/dashboard/mixin";
import { helper } from "@/packages/dashboard/utils";
export default {
  name: "projectTeam",
  props: {
    projectTeam: {
      //type: Array,
      default: () => [],
    },
    line: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [FileMixin],
  data: function () {
    return {
      teamProfile: {
        documentNo: "",
        //lineNo: "",
        fullName: "",
        institution: "",
        gender: "",
        email: "",
        telephone: "",
        idPassportNo: "",
        dateOfBirth: "",
        personWithDisability: false,
        //county: "",
        primaryRole: "",
        qualifications: "",
        attachments: [],
        location: "",
      },
      //file:"",
      isValid: false,
      dialog: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Name",
          value: "fullName",
        },
        {
          text: "ID No.",
          value: "idPassportNo",
        },
        {
          text: "Telephone",
          value: "telephone",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "County",
          value: "location",
        },

        {
          text: "Gender",
          value: "gender",
        },
        {
          text: "Institution",
          value: "institution",
        },
        {
          text: "Primary Role",
          value: "primaryRole",
        },
        {
          text: "Qualifications",
          value: "qualifications",
        },
        // {
        //   text: "Attachments",
        //   value: "Attachments",
        // },

        {
          text: "Actions",
          value: "actions",
        },
      ];
    },

    rules() {
      return {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],

        required: [(v) => !!v || "Field is required"],
      };
    },
  },
  methods: {
    remove(projectTeam) {
      this.$store.dispatch("Application/deleteProjectTeam", {
        lineNo: projectTeam.lineNo,
        documentNo: projectTeam.documentNo,
        type: projectTeam.type,
      });

      this.$emit("delete-profile");
    },
    //save team member
    saveProjectTeamProfile() {
      if (!this.validateProjectTeam(this.teamProfile)) {
        return;
      }
      this.$refs.projectTeamForm.validate();
      if (this.isValid) {
        this.$emit("add-member", this.teamProfile);
        this.resetFormFields();

        this.dialog = false;
      }
    },
    resetFormFields() {
      for (const key in this.teamProfile) {
        this.teamProfile[key] = "";
      }
    },

    validateProjectTeam(teamProfile) {
      if (
        !teamProfile.fullName ||
        !teamProfile.institution ||
        !teamProfile.gender ||
        !teamProfile.email ||
        !teamProfile.telephone ||
        !teamProfile.dateOfBirth ||
        !teamProfile.location ||
        !teamProfile.primaryRole ||
        !teamProfile.qualifications
      ) {
        return false;
      }
      return true;
    },
    attachFile() {
      this.$refs.file.click();
    },
    async uploadFile(e) {
      console.log("event file", e[0]);
      const file = e[0];
      if (file) {
        let base64File = await helper.toBase64(file);
        base64File = base64File.split(",")[1];
        console.log("encoded file", base64File);
        this.teamProfile.attachments.push({
          attachment: base64File,
          fileName: file.name,
        });
      }
      console.log("Attachment", this.teamProfile.attachments);
    },
  },
  watch: {},
};
</script>
