import call from "@/service/http";
import setupConstants from "./setupConstants";

export default {
  namespaced: true,
  state: {
    applicationSetup: [],
    counties: [],
    checklists: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    setupGetters: (state) => (val) => state[val],
  },
  actions: {
    getApplicationSetup: ({ commit }, no) => {
      call("get", setupConstants.applicationSetup(no))
        .then((res) => {
          commit("MUTATE", { state: "applicationSetup", value: res.data.data });
          //add to the localStorage
          localStorage.setItem(
            "applicationSetup",
            JSON.stringify(res.data.data)
          );
        })
        .catch((err) => {
          console.error("Application Setup Error: ", err.response.data.message);
        });
    },
    getCounties: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", setupConstants.counties)
        .then((res) => {
          commit("MUTATE", { state: "counties", value: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },

    /**fn ()-> checklists */
    getChecklists: ({ commit }) => {
      call("get", setupConstants.checklistsSetup)
        .then((res) => {
          commit("MUTATE", { state: "checklists", value: res.data.data });
        })
        .catch((err) => {
          console.error("Checklists Setup Error: ", err.response.data.message);
        });
    },
  },
};
