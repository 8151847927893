import call from "@/service/http";

import { AuthConstants as constant, AuthService } from "./index";

export default {
  namespaced: true,
  state: {
    otpModal: false,
    openforgotOtp: false,
    //user: {},
    loading: false,
    company: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
    SET_COMPANY: (state, data) => {
      state.company = data;
    },
    SET_OTP_MODAL: (state, payload) => {
      state.otpModal = payload;
    },
    SET_FORGOT_OTP_MODAL: (state, payload) => {
      state.openforgotOtp = payload;
    },
  },
  getters: {
    // authGetters: (state) => (val) => state[val],
    user: () => AuthService.user,
    company: (state) => state.company,
    otpModal: (state) => state.otpModal,
    openforgotOtp: (state) => state.openforgotOtp,
  },
  actions: {
    login: ({ commit }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constant.login, payload)
        .then((res) => {
          if (res.data.data.authStatus === "success") {
            commit("Dashboard/SET_LOADING", false, { root: true });
            commit("MUTATE", { state: "user", value: res.data.data.user });
            AuthService.login(res.data.data.token, res.data.data.user);
            Event.$emit("ApiSuccess", "Login Successful");
            Event.$emit("login-form", false);
          } else {
            if (res.data.data.authStatus === "error") {
              Event.$emit("ApiError", res.data.data.email[0]);
              commit("Dashboard/SET_LOADING", false, { root: true });
            }
          }
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: false });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    //handle logout
    logout: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.logout)
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          AuthService.logout();
        })
        .catch((error) => {
          console.log(error);
          commit("Dashboard/SET_LOADING", true, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    company: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", constant.company)
        .then((res) => {
          commit("SET_COMPANY", res.data.data);
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          Event.$emit("ApiError", "Failed To fetch company info");
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    //user registration
    register: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constant.register, data)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.data.message);
          dispatch("openOtp", true);
          // Event.$emit("routeBack");
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    sendOtp: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constant.sendOtp, data)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.data.message);
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    verifyOtp: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constant.verifyOtp, data)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          AuthService.login(res.data.data.token, res.data.data.user);
          Event.$emit("redirectGrantApplication");
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    verifyforgotOtp: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constant.verifyOtp, data)
        .then(() => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("otp-verification-success");
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    openOtp({ commit }, payload) {
      console.log(payload);
      commit("SET_OTP_MODAL", payload);
    },
    openforgotOtp({ commit }, payload) {
      //console.log(payload);
      commit("SET_FORGOT_OTP_MODAL", payload);
    },
    forgotPassword: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", constant.passwordReset, data)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.data.message);
          Event.$emit("forgot-otp-sent");
        })
        .catch((err) => {
          console.log(err);
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    setPassword: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });

      call("post", constant.setPassword, data)
        .then((res) => {
          AuthService.login(res.data.data.token, res.data.data.user);
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    resetPassword: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });

      call("post", constant.password, data)
        .then(() => {
          // AuthService.login(res.data.data.token, res.data.data.user);
          Event.$emit("password-set-success");
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", err.response.data.message);
        });
    },
  },
};
